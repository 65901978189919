import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Loader from '../../../../components/Common/Loader/Loader';
import NotFound from '../../../not-found/NotFound';
import DeliveryVehicleForm from '../../../../components/SiteAdmin/DeliveryVehicle/DeliveryVehicleForm';

import getDeliveryVehicleQuery from './getDeliveryVehicleQuery.graphql';
import messages from '../../../../locale/messages';

import s from './EditDeliveryVehicle.css';

class EditDeliveryVehicle extends React.Component {
  static defaultProps = {
    deliveryVehicle: {
      loading: true
    }
  };

  render() {
    const { title, id, deliveryVehicle: { getDeliveryVehicle, loading }, isAdmin } = this.props;

    let initialValues = {};

    if (!loading && getDeliveryVehicle) {
      initialValues = getDeliveryVehicle && getDeliveryVehicle.result;
    }

    if (!loading && !getDeliveryVehicle?.result) {
      return <NotFound title={'Page Not Found'} isAdmin />
    } else {
      return (
        <Loader show={loading} type={"page"}>
          <div className={s.root}>
            <div className={s.container}>
              <div className={s.heading}>
                <FormattedMessage {...messages.editDeliveryVehicle} />
              </div>
              <div className={s.paddingRoutesSection}>
                {
                  !loading && <DeliveryVehicleForm initialValues={initialValues} id={id} />
                }
              </div>
            </div>
          </div>
        </Loader>

      );
    }
  }
}

export default compose(
  withStyles(s),
  graphql(getDeliveryVehicleQuery, {
    name: 'deliveryVehicle',
    options: (props) => ({
      ssr: false,
      fetchPolicy: 'network-only',
      variables: {
        id: props.id
      }
    })
  }),
)(EditDeliveryVehicle);
