import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';

import ViewBookingTransactionDetails from '../../../components/SiteAdmin/Booking/ViewBookingTransactionDetails';
import Loader from '../../../components/Common/Loader/Loader';

import viewTransactionHistoryDetails from './viewBookingTransactionHistory.graphql';

import s from './viewBookingTransactionHistory.css';
import NotFound from '../../not-found/NotFound';
export class ViewBookingTransactionHistory extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        data: {
            loading: true
        },
        pageType: 'bookings'
    };

    render() {
        const { id, data: { loading, refetch, getAdminTransactionHistory }, title, pageType } = this.props;
        if (!loading && (!getAdminTransactionHistory?.result || getAdminTransactionHistory?.status !== 200)) {
            return <NotFound title={'Page Not Found'} isAdmin />;
        } else {
            return (
                <Loader type={"page"} show={loading}>
                    <div className={s.root}>
                        <div className={s.container}>
                            <div className={s.paddingRoutesSection}>
                                {
                                    !loading && getAdminTransactionHistory && getAdminTransactionHistory.status === 200 && <ViewBookingTransactionDetails
                                        data={getAdminTransactionHistory.result}
                                        title={title}
                                        pageType={pageType}
                                        refetch={refetch}
                                    />
                                }
                                {/* {
                                    !loading && getAdminTransactionHistory && getAdminTransactionHistory.status !== 200 && <div>
                                        {getAdminTransactionHistory.errorMessage}
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </Loader>
            )
        }

    }
}

export default compose(
    withStyles(s),
    graphql(viewTransactionHistoryDetails, {
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    })
)(ViewBookingTransactionHistory);
