exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fivA8 {\n  color: red\n}\n\n._3ZPN4 {\n  margin-top: 0;\n  font-size: 30px;\n  color: var(--title-color);\n}\n\n._3C7Xl {\n  font-weight: 500;\n  font-size: 16px;\n  margin-bottom: 18px;\n  color: var(--title-color);\n}\n\n.UldgX {\n  display: inline-block !important;\n}\n\n._3Anqw {\n  width: 15%;\n}\n\n._1SKo3 {\n  width: 85%;\n}\n\n._14tbT {\n  width: 25%;\n}\n\n._1isQy {\n  display: inline-block;\n  float: right;\n  width: 50%;\n}\n\n._2xVcz {\n  padding-left: 8px;\n}\n\n._29roo {\n  vertical-align: middle;\n}\n\n.eHo9Q {\n  float: none;\n  display: inline-block;\n  width: 50%;\n  padding: 0px 5px;\n}\n\n._1FEVM {\n  width: 100%;\n  display: block;\n  margin-top: 0px;\n}\n\n._2BMLV {\n  border: 0px !important;\n}\n\n.ZCgoE {\n  color: var(--title-color) !important;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.eHo9Q label {\n  font-weight: 400;\n}\n\n._2ZHx2 {\n  vertical-align: top;\n}\n\n@media (max-width: 1200px) and (min-width: 992px) {\n  ._3Anqw {\n    width: 25%;\n  }\n\n  ._1SKo3 {\n    width: 75%;\n  }\n\n  ._14tbT {\n    width: 50%;\n  }\n}\n\n@media screen and (max-width: 1199px) {\n  ._2Uo8f {\n    font-size: 14px;\n  }\n}\n\n@media screen and (max-width: 991px) {\n  ._3Anqw {\n    width: 100%;\n  }\n\n  ._1SKo3 {\n    width: 100%;\n  }\n\n  ._14tbT {\n    width: 100%;\n  }\n\n  ._1isQy {\n    display: inline-block;\n    float: right;\n    width: 100%;\n  }\n}", ""]);

// exports
exports.locals = {
	"errorMessage": "fivA8",
	"titleText": "_3ZPN4",
	"daysText": "_3C7Xl",
	"displayInlineblock": "UldgX",
	"switchSection": "_3Anqw",
	"radioBtnSection": "_1SKo3",
	"radioBtnLabelSection": "_14tbT",
	"timeSection": "_1isQy",
	"paddingLeft": "_2xVcz",
	"vtrMiddle": "_29roo",
	"timeInline": "eHo9Q",
	"tableText": "_1FEVM",
	"noBorder": "_2BMLV",
	"labelTitleText": "ZCgoE",
	"vtrTop": "_2ZHx2",
	"selectText": "_2Uo8f"
};