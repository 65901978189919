exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** Common - Start **/\n._27bhL {\n\tpadding: 12px 27px;\n\tborder-color: #c4c4c4;\n\tbackground: white;\n\tcolor: var(--title-color);\n\tfont-weight: 500;\n\tfont-size: 16px;\n\tborder-radius: 0px;\n}\n._27bhL:hover,\n._27bhL:focus,\n._27bhL:hover:focus {\n\tbackground: white;\n\tcolor: var(--title-color);\n}\n.q-mdL {\n\tborder-color: #9D7A62 !important;\n\tbackground-color: #9D7A62 !important;\n\tcolor: #fff !important;\n\tdisplay: block;\n\tmax-width: 200px;\n\tmargin: 0 auto;\n\tmargin-top: 30px !important;\n\tborder-radius: 0px;\n\tfont-weight: 700 !important;\n\tcursor: pointer;\n\ttext-decoration: none !important;\n}\n.q-mdL:hover,\n.q-mdL:focus,\n.q-mdL:active,\n.q-mdL:active:hover,\n.q-mdL:active:focus {\n\tborder-color: #9D7A62;\n\tbackground-color: #9D7A62;\n\tcolor: #fff;\n}\n/** Common - End **/\n/** News box - Start */\n._3SKre {\n\tcolor: var(--title-color);\n\tmargin: 0px;\n\tword-wrap: break-word;\n\tfont-size: 26px;\n\tpadding-top: 2px;\n\tpadding-bottom: 2px;\n\tfont-weight: 500;\n}\n._2iGgB {\n\tbackground-repeat: no-repeat;\n\tbackground-size: cover;\n\twidth: 100%;\n\tbackground-size: cover;\n\tbackground-repeat: no-repeat;\n\tbackground-position: 50% 50%;\n\theight: 100%;\n\tpadding: 10px 15px;\n}\n._3c-1Q {\n\tdisplay: block;\n\twidth: 100%;\n\tmax-width: 700px;\n\tbackground: #fff;\n\topacity: 0.9;\n\tpadding: 50px 35px;\n\tmargin: 0 auto;\n\tmargin-top: 100px !important;\n\tmargin-bottom: 100px !important;\n\tborder-radius: 0px;\n\ttext-align: center;\n}\n._2BiLi {\n\tposition: relative;\n}\n._1J-NL {\n\tpadding-bottom: 80px;\n}\n._3Om9f {\n\tpadding: 0px;\n}\n@media screen and (max-width: 767px) {\n\t._3SKre {\n\t\tfont-size: 22px;\n\t\tline-height: 30px;\n\t}\n\n\t._3c-1Q {\n\t\twidth: auto;\n\t\tmax-width: 400px;\n\t\tpadding: 30px 18px;\n\t}\n\n\t._1vfmZ {\n\t\tpadding: 0px;\n\t}\n}\n/** News box - Start */", ""]);

// exports
exports.locals = {
	"btn": "_27bhL",
	"btnPrimary": "q-mdL",
	"title": "_3SKre",
	"tapBannerImage": "_2iGgB",
	"contentSection": "_3c-1Q",
	"positionRelative": "_2BiLi",
	"root": "_1J-NL",
	"noPadding": "_3Om9f",
	"responsiveNoPadding": "_1vfmZ"
};