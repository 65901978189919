exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2W1sz {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n.CpU2p {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: 380px;\n\n}\n\n._1MhqW {\n  margin-bottom: 6px;\n  position: relative;\n}\n\n.ucyzy {\n  overflow: hidden;\n}\n\n.gVaNU {\n  position: relative;\n  z-index: 1;\n  display: block;\n  margin: 15px 0px;\n  width: 100%;\n  color: #767676;\n  text-align: center;\n  font-size: 14px;\n}", ""]);

// exports
exports.locals = {
	"root": "_2W1sz",
	"container": "CpU2p",
	"formGroup": "_1MhqW",
	"formSection": "ucyzy",
	"horizontalLineThrough": "gVaNU"
};