import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';

import CustomPagination from '../../../CustomPagination';
import CurrencyConverter from '../../../CurrencyConverter';
import Link from '../../../Link';

import messages from '../../../../locale/messages';
import getAllPromoCode from './getAllPromoCode.graphql';

import { deletePromoCode } from '../../../../actions/siteadmin/PromoCode/deletePromoCode';
import { addPromoCode } from '../../../../actions/siteadmin/PromoCode/addPromoCode';

import TrashIcon from '../../../../../public/Icons/bin.svg';
import EditIcon from '../../../../../public/Icons/edit.png';
import s from './PromoCodeList.css';

class PromoCodeList extends React.Component {
  static propTypes = {
    deleteDriver: PropTypes.any
  };

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0
    }
  }

  handleSearchClick = (searchList) => {
    const { promoCodes: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange = (e) => {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  handleChange = async (e, id, title, description, code, type, promoValue) => {
    const { addPromoCode, promoCodes: { refetch } } = this.props;
    let data = {};
    data = {
      id: id,
      title: title,
      description: description,
      code: code,
      type: type,
      promoValue: promoValue,
      isEnable: e.target.value
    }
    this.setState({ currentPage: 1 });
    let variables = { currentPage: 1 };
    await addPromoCode(data);
    refetch(variables);
  }

  handleDelete = async (id, currentPage) => {
    const { deletePromoCode, promoCodes: { refetch, getPromoCodes } } = this.props;
    let variables = { currentPage: currentPage > 1 && getPromoCodes?.data?.length == 1 ? currentPage - 1 : currentPage };
    const response = await deletePromoCode(id);
    this.setState({
      currentPage: variables.currentPage
    });
    if (response?.status == 200) {
      refetch(variables);
    }
  }

  paginationData = (currentPage) => {
    const { promoCodes: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  render() {
    const { promoCodes, currency, } = this.props;
    const { currentPage } = this.state;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.exportDisplay, s.tabelExport)}>
          <div>
            <div className={s.searchInput}>
              <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
            </div>
          </div>
          <div className={cx(s.exportTextSection, 'textAlignLeftRTL')}>
            <div className={cx(s.paddingTop2)}>
              <Link to={'/siteadmin/promo-code/add'} className={s.addlinkBtn}>
                <FormattedMessage {...messages.addPromoCode} />
              </Link>
            </div>
          </div>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.code} /></th>
                <th scope="col"><FormattedMessage {...messages.title} /></th>
                <th scope="col"><FormattedMessage {...messages.discount} /></th>
                <th scope="col"><FormattedMessage {...messages.promoType} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
                <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
              </tr>
            </thead>
            <tbody>
              {
                promoCodes?.getPromoCodes?.data && promoCodes?.getPromoCodes?.data?.length > 0 && promoCodes?.getPromoCodes?.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item && item.id}</td>
                      <td data-label={formatMessage(messages.code)}>{item && item.code}</td>
                      <td data-label={formatMessage(messages.title)}>{item && item.title}</td>
                      <td data-label={formatMessage(messages.discount)}>
                        {
                          item && item.type === 1 && <span>
                            {item.promoValue + '%'}
                          </span>
                        }
                        {
                          item && item.type !== 1 && <CurrencyConverter
                            amount={item.promoValue}
                            from={item.currency}
                            toCurrency={currency}
                          />
                        }
                      </td>
                      <td data-label={formatMessage(messages.promoType)}>{item && item.type === 1 ? formatMessage(messages.percentage) : formatMessage(messages.fixedAmount)}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select name="isEnable" onChange={(e) => this.handleChange(e, item.id, item.title, item.description, item.code, item.type, item.promoValue)} value={item.isEnable} className={s.selectInput}>
                          <option value="true">{formatMessage(messages.active)}</option>
                          <option value="false">{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/promo-code/edit/' + item.id} className={'editAlignIcon'}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                      </td>
                      <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={s.iconBtn}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                promoCodes?.getPromoCodes?.data && promoCodes?.getPromoCodes?.data?.length == 0 && (
                  <tr>
                    <td colSpan={50} className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          promoCodes?.getPromoCodes && promoCodes?.getPromoCodes?.count > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={promoCodes?.getPromoCodes?.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.promoCodeId)}
            />
          </div>
        }
      </div>
    );
  }
}

const mapState = (state) => ({});
const mapDispatch = {
  deletePromoCode,
  addPromoCode
};

export default compose(
  withStyles(s),
  injectIntl,
  graphql(getAllPromoCode, {
    name: 'promoCodes',
    options: {
      variables: {
        currentPage: 1,
        searchList: ''
      },
      ssr: true,
      fetchPolicy: 'network-only'
    }
  }),
  connect(mapState, mapDispatch)
)(PromoCodeList);