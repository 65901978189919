import messages from '../../../../locale/messages';
import { inputDescLimit, inputTextLimit } from '../../../../helpers/fieldRestriction';

const validate = values => {
    const errors = {};

    if (!values.sliderTitle5) {
        errors.sliderTitle5 = messages.required;
    } else if (values.sliderTitle5.trim() == "") {
        errors.sliderTitle5 = messages.required;
    } else if (values.sliderTitle5.length < 2 ) {
        errors.sliderTitle5 = messages.required
    } else if (values.sliderTitle5.length > inputTextLimit) {
        errors.sliderTitle5 = messages.inputTextLimitError
    }

    if (!values.sliderTitle1) {
        errors.sliderTitle1 = messages.required;
    } else if (values.sliderTitle1.trim() == "") {
        errors.sliderTitle1 = messages.required;
    } else if (values.sliderTitle1.length < 2 ) {
        errors.sliderTitle1 = messages.required
    } else if (values.sliderTitle1.length > inputTextLimit) {
        errors.sliderTitle1 = messages.inputTextLimitError
    }

    if (!values.sliderContent1) {
        errors.sliderContent1 = messages.required;
    } else if (values.sliderContent1.trim() == "") {
        errors.sliderContent1 = messages.required;
    } else if (values.sliderContent1.length < 2 ) {
        errors.sliderContent1 = messages.required
    } else if (values.sliderContent1.length > inputDescLimit) {
        errors.sliderContent1 = messages.inputDescLimitError
    }


    if (!values.sliderTitle2) {
        errors.sliderTitle2 = messages.required;
    } else if (values.sliderTitle2.trim() == "") {
        errors.sliderTitle2 = messages.required;
    } else if (values.sliderTitle2.length < 2 ) {
        errors.sliderTitle2 = messages.required
    } else if (values.sliderTitle2.length > inputTextLimit) {
        errors.sliderTitle2 = messages.inputTextLimitError
    }

    if (!values.sliderContent2) {
        errors.sliderContent2 = messages.required;
    } else if (values.sliderContent2.trim() == "") {
        errors.sliderContent2 = messages.required;
    } else if (values.sliderContent2.length < 2 ) {
        errors.sliderContent2 = messages.required
    } else if (values.sliderContent2.length > inputDescLimit) {
        errors.sliderContent2 = messages.inputDescLimitError
    }


    if (!values.sliderTitle3) {
        errors.sliderTitle3 = messages.required;
    } else if (values.sliderTitle3.trim() == "") {
        errors.sliderTitle3 = messages.required;
    } else if (values.sliderTitle3.length < 2 ) {
        errors.sliderTitle3 = messages.required
    } else if (values.sliderTitle3.length > inputTextLimit) {
        errors.sliderTitle3 = messages.inputTextLimitError
    }

    if (!values.sliderContent3) {
        errors.sliderContent3 = messages.required;
    } else if (values.sliderContent3.trim() == "") {
        errors.sliderContent3 = messages.required;
    } else if (values.sliderContent3.length < 2 ) {
        errors.sliderContent3 = messages.required
    } else if (values.sliderContent3.length > inputDescLimit) {
        errors.sliderContent3 = messages.inputDescLimitError
    }

    if (!values.sliderTitle4) {
        errors.sliderTitle4 = messages.required;
    } else if (values.sliderTitle4.trim() == "") {
        errors.sliderTitle4 = messages.required;
    } else if (values.sliderTitle4.length < 2 ) {
        errors.sliderTitle4 = messages.required
    } 

    if (!values.sliderContent4) {
        errors.sliderContent4 = messages.required;
    } else if (values.sliderContent4.trim() == "") {
        errors.sliderContent4 = messages.required;
    } else if (values.sliderContent4.length < 2 ) {
        errors.sliderContent4 = messages.required
    } else if (values.sliderContent4.length > 200 ) {
        errors.sliderContent4 = messages.exceedLimit
    } else if (values.sliderContent4.length > inputDescLimit) {
        errors.sliderContent4 = messages.inputDescLimitError
    }


    return errors;
};

export default validate;