import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import showErrorMessage from '../../../helpers/errorMessages/showErrorMessage';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { addMutation, updateMutation } from '../../lib/graphql';
import {
  ADD_UPDATE_DELIVERY_VEHICLE_START,
  ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
  ADD_UPDATE_DELIVERY_VEHICLE_ERROR
} from '../../../constants';

export function addUpdateDeliveryVehicle(
  id,
  vehicleName,
  vehicleImage,
  description,
  isActive
) {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_START,
        payload: {
          pricingLoading: true
        }
      });

      dispatch(setLoaderStart('DeliveryVehicleForm'));

      let mutation, variables;
      mutation = (id) ? updateMutation : addMutation;

      variables = {
        id,
        vehicleName,
        vehicleImage,
        description,
        isActive: (isActive == true || 'true') ? 1 : 0
      };

      const { data } = await client.mutate({
        mutation,
        variables
      });

      const response = id ? data.updateDeliveryVehicle : data.addDeliveryVehicle;

      if (response?.status === 200) {

        history.push('/siteadmin/vehicle-type/list');

        showToaster({ messageId: 'vehicleTypeStatusSuccess', toasterType: 'success', requestContent: id ? 'updated' : 'added' });

        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_SUCCESS,
          payload: {
            pricingLoading: false
          }
        });

        dispatch(setLoaderComplete('DeliveryVehicleForm'));

        return {
          status: 200
        };
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: response?.errorMessage })

        await dispatch({
          type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
          payload: {
            pricingLoading: false,
            error: response?.errorMessage
          }
        });

        dispatch(setLoaderComplete('DeliveryVehicleForm'));

        return {
          status: 400
        };
      }
    } catch (error) {

      showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error })

      await dispatch({
        type: ADD_UPDATE_DELIVERY_VEHICLE_ERROR,
        payload: {
          pricingLoading: false,
          error: await showErrorMessage({ errorCode: 'catchError', error })
        }
      });

      dispatch(setLoaderComplete('DeliveryVehicleForm'));

      return {
        status: 400
      };
    }
  }
}