import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
// style
import s from './ViewBookingTransactionDetails.css';
import rs from '../../../restaurantCommon.css';

// helpers
import messages from '../../../../locale/messages';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirst';
import { bookingStatus } from '../../../../helpers/bookingStatus';
// components
import Link from '../../../Link';
import ViewTransactionAccordion from './ViewTransactionAccordion';
import ViewRefundAccordion from './ViewRefundAccordion';
// redux actions
import { updatePayoutStatus } from '../../../../actions/siteadmin/Payout/updatePayout';

export class ViewBookingTransactionDetails extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    locale: 'en-US',
    pageType: null,
  };

  constructor(props) {
    super(props);
    this.handleHoldPayout = this.handleHoldPayout.bind(this);
    this.isValueDefined = this.isValueDefined.bind(this);
    this.renderInformation = this.renderInformation.bind(this);
    this.renderUserInformation = this.renderUserInformation.bind(this);
    this.renderPaidStatus = this.renderPaidStatus.bind(this);
  }

  isValueDefined(value) {
    if (value !== null && value !== undefined && value !== '') {
      return true;
    }
    return false;
  }

  renderInformation(label, information) {
    if (label && information) {
      return (
        <tr>
          <td className={s.tableLabelColumn}>
            <b>{label}</b>
          </td>
          <td>{information}</td>
        </tr>
      );
    }
  }

  renderUserInformation(label, information, userType) {
    if (label && information) {
      return (
        <tr>
          <td>
            <b>{label}</b>
          </td>
          <td>
            {userType !== 3 && capitalizeFirstLetter(information.firstName)}
            {userType === 3 && capitalizeFirstLetter(information.shopName)}
            <br />
            {information.phoneNumber}
            <br />
            {information.email}
            <br />
          </td>
        </tr>
      );
    }
  }

  renderPaidStatus(status, isPaidLabelType) {
    const { formatMessage } = this.props.intl;
    if (isPaidLabelType) {
      return status
        ? formatMessage(messages.paidLabel)
        : formatMessage(messages.notPaidLabel);
    }
    return status ? formatMessage(messages.yes) : formatMessage(messages.no);
  }

  async handleHoldPayout(e, id, fieldName) {
    const { updatePayoutStatus, refetch } = this.props;
    let isHold = e.target.value;
    isHold = isHold == 'true';
    await updatePayoutStatus(id, isHold, fieldName);
    await refetch(id);
  }

  render() {
    const { title, data, pageType } = this.props;
    const { formatMessage, locale } = this.props.intl;
    const paymentTypeLabel = {
      1: formatMessage(messages.cashOnHand),
      2: formatMessage(messages.card),
      3: formatMessage(messages.wallet),
    };
    const eligibleStatusForUser = ['declined', 'expired', 'deliveryNotFound'];
    const eligibleStatusForShop = ['completed', 'failed'];
    const eligibleStatusForDriver = ['completed', 'failed'];
    const orderStatus = data && data.bookingStatus;
    let link = '/siteadmin/bookings';
    if (pageType !== 'bookings') {
      link = `/siteadmin/${pageType}`;
    }

    return (
      <div className={cx(s.pagecontentWrapper, s.widthInner, s.space5)}>
        <div className={s.contentBox}>
          <Row>
            <Col lg={8} md={8} sm={8} xs={12}>
              <h1 className={cx(s.titleTextAdmin, s.space2)}>
                <FormattedMessage {...messages.bookingPaymentDetails} />
              </h1>
            </Col>
            <Col
              lg={4}
              md={4}
              sm={4}
              xs={12}
              className={cx(s.textAlignRight, s.space2, 'textAlignLeftRTL')}
            >
              <Link
                to={link}
                className={cx(
                  'pull-right',
                  s.backBtn,
                  s.backBtn,
                  s.btnSecondary,
                )}
              >
                <FormattedMessage {...messages.goBack} />
              </Link>
            </Col>
          </Row>
          <div
            className={cx(
              'table-responsive',
              'bookingCollapse',
              'whiteDropdown',
            )}
          >
            <Table>
              <tbody>
                {data.orderId &&
                  this.renderInformation(
                    formatMessage(messages.orderId),
                    `#${data.orderId}`,
                  )}
                {data.userDetails &&
                  this.renderUserInformation(
                    formatMessage(messages.eaterDetails),
                    data.userDetails,
                  )}
                {data.shopDetails &&
                  this.renderUserInformation(
                    formatMessage(messages.shopDetails),
                    data.shopDetails,
                    3,
                  )}
                {data.deliveryPartnerDetails &&
                  this.renderUserInformation(
                    formatMessage(messages.deliveryPartnerDetails),
                    data.deliveryPartnerDetails,
                  )}
                {this.renderInformation(
                  formatMessage(messages.orderCreatedAt),
                  moment(data.bookingCreatedAt).format('MM-DD-YYYY hh:mm:ss a'),
                )}
                {this.renderInformation(
                  formatMessage(messages.orderStatus),
                  bookingStatus(orderStatus, locale),
                )}
                {this.renderInformation(
                  formatMessage(messages.payment),
                  data.payment === 'pending'
                    ? formatMessage(messages.notPaidLabel)
                    : formatMessage(messages.paidLabel),
                )}
                {this.renderInformation(
                  formatMessage(messages.paymentType),
                  paymentTypeLabel[data.paymentType],
                )}
                {eligibleStatusForUser.indexOf(orderStatus) >= 0 &&
                  data.paymentType !== 1 &&
                  this.renderInformation(
                    formatMessage(messages.refundStatus),
                    this.renderPaidStatus(data.isRefund, true),
                  )}
                {eligibleStatusForShop.indexOf(orderStatus) >= 0 &&
                  this.renderInformation(
                    formatMessage(messages.restaurantPayout),
                    this.renderPaidStatus(data.isPayoutPaid, true),
                  )}
                {eligibleStatusForShop.indexOf(orderStatus) >= 0 &&
                  data.isPayoutPaid !== true && (
                    <tr>
                      <td>
                        <b>{formatMessage(messages.isShopPayoutHold)}</b>
                      </td>
                      <td>
                        <select
                          value={data.isHoldPayoutForShop}
                          onChange={e => {
                            this.handleHoldPayout(
                              e,
                              data.id,
                              'isHoldPayoutForShop',
                            );
                          }}
                          className={s.selectInput}
                        >
                          <option value>{formatMessage(messages.yes)}</option>
                          <option value={false}>
                            {formatMessage(messages.no)}
                          </option>
                        </select>
                      </td>
                    </tr>
                  )}
                {eligibleStatusForDriver.indexOf(orderStatus) >= 0 &&
                  this.renderInformation(
                    formatMessage(messages.driverPayout),
                    this.renderPaidStatus(data.isPayoutPaidForDelivery, true),
                  )}
                {eligibleStatusForDriver.indexOf(orderStatus) >= 0 &&
                  data.isPayoutPaidForDelivery !== true && (
                    <tr>
                      <td>
                        <b>{formatMessage(messages.isDriverPayoutHold)}</b>
                      </td>
                      <td>
                        <select
                          value={data.isHoldPayoutForDelivery}
                          onChange={e => {
                            this.handleHoldPayout(
                              e,
                              data.id,
                              'isHoldPayoutForDelivery',
                            );
                          }}
                          className={s.selectInput}
                        >
                          <option value>{formatMessage(messages.yes)}</option>
                          <option value={false}>
                            {formatMessage(messages.no)}
                          </option>
                        </select>
                      </td>
                    </tr>
                  )}
              </tbody>
            </Table>
            {eligibleStatusForUser.indexOf(orderStatus) >= 0 &&
              data.paymentType !== 1 && (
                <ViewRefundAccordion
                  headerText={formatMessage(messages.userRefundHistory)}
                  data={data.userRefundHistory}
                  requestType="success"
                />
              )}
            {eligibleStatusForUser.indexOf(orderStatus) >= 0 &&
              data.paymentType !== 1 && (
                <ViewRefundAccordion
                  headerText={formatMessage(messages.userFailedRefundHistory)}
                  data={data.userFailedRefundHistory}
                  requestType="failed"
                />
              )}
            {eligibleStatusForShop.indexOf(orderStatus) >= 0 && (
              <ViewTransactionAccordion
                headerText={formatMessage(messages.shopPayoutHistory)}
                data={data.shopPayoutHistory}
                requestType="success"
              />
            )}
            {eligibleStatusForShop.indexOf(orderStatus) >= 0 && (
              <ViewTransactionAccordion
                headerText={formatMessage(messages.shopFailedPayoutHistory)}
                data={data.shopFailedPayoutHistory}
                requestType="failed"
              />
            )}
            {eligibleStatusForDriver.indexOf(orderStatus) >= 0 && (
              <ViewTransactionAccordion
                headerText={formatMessage(messages.driverPayoutHistory)}
                data={data.driverPayoutHistory}
                requestType="success"
              />
            )}
            {eligibleStatusForDriver.indexOf(orderStatus) >= 0 && (
              <ViewTransactionAccordion
                headerText={formatMessage(messages.driverFailedPayoutHistory)}
                data={data.driverFailedPayoutHistory}
                requestType="failed"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  locale: state.intl.locale,
});
const mapDispatch = {
  updatePayoutStatus,
};

export default injectIntl(
  withStyles(
    s,
    rs,
  )(connect(mapState, mapDispatch)(ViewBookingTransactionDetails)),
);
