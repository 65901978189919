import React, { Component } from 'react';
import PreparationTime from '../../../components/Shop/PreparationTime/PreparationTime'
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './PreparationTimeContainer.css';
import Loader from '../../../components/Common/Loader/Loader';
import getPreparationTime from './getPreparationTime.graphql';


export class PreparationTimeContainer extends Component {

    static defaultProps = {
        data: {
            loading: false
        }
    }
    render() {
        const { getPreparationTime: { loading, getPreparationTime } } = this.props;

        return (
            <Loader type={'page'} show={loading}>
                <div className={s.root}>
                    <div className={s.container}>
                        {!loading && <PreparationTime initialValues={getPreparationTime} />}
                    </div>
                </div>
            </Loader>
        )
    }
}

export default compose(withStyles(s),
    graphql(getPreparationTime, {
        name: 'getPreparationTime',
        options: {
            fetchPolicy: 'network-only',
            ssr: false
        }
    })
)(PreparationTimeContainer)