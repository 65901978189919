exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2aJy2 {\n    border: 1px solid #ced4da;\n    padding: 20px;\n    background: #fff;\n    text-align: center;\n    border-radius: 2px;\n}\n\n._73pJ5:hover,\n._73pJ5:active {\n    text-decoration: none;\n}\n\n._3h5kb {\n    width: 100%;\n    max-width: 70px;\n    display: block;\n    margin: 0 auto;\n    margin-bottom: 6px;\n}\n\n._1JYmQ {\n    font-size: 16px;\n    font-weight: 500;\n    word-break: break-word;\n    color: var(--title-color);\n    display: block;\n}\n\n._1borp {\n    font-size: 20px;\n    font-weight: 600;\n    color: var(--title-color);\n    margin-bottom: 0px;\n    word-break: break-word;\n}\n\n._390MQ {\n    width: 100%;\n    display: block;\n    margin: 0 auto;\n    margin-bottom: 6px;\n    position: absolute;\n    max-width: 48px;\n    right: 20px;\n    top: 6px;\n}\n\n._1Jg9M {\n    border-left: 3px solid #9D7A62;\n    text-align: left !important;\n}\n\n._24Gso {\n    min-width: 200px !important;\n    width: 100%;\n}\n\n@media screen and (max-width: 991px) {\n    ._37OXQ {\n        text-align: left !important;\n    }\n}", ""]);

// exports
exports.locals = {
	"mainContainer": "_2aJy2",
	"mainContainerLink": "_73pJ5",
	"orderImg": "_3h5kb",
	"subtext": "_1JYmQ",
	"numberText": "_1borp",
	"earnImg": "_390MQ",
	"earnSection": "_1Jg9M",
	"selectWidth": "_24Gso",
	"mobileTextLeft": "_37OXQ"
};