import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CustomPagination from '../../../CustomPagination';
import Link from '../../../Link';

import history from '../../../../history';
import messages from '../../../../locale/messages';
import updateCategoryStatus from '../../../../actions/siteadmin/Category/updateCategoryStatus';
import deleteCategory from '../../../../actions/siteadmin/Category/deleteCategory';
import updateCategoryPrivilege from '../../../../actions/siteadmin/Category/updateCategoryPrivilege';

import EditIcon from '../../../../../public/Icons/edit.png';
import TrashIcon from '../../../../../public/Icons/bin.svg';

import s from './CategoryList.css';

class CategoryList extends React.Component {
  static propTypes = {
    categoryDetails: PropTypes.object,
  };

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 1,
      searchList: '',
      typing: false,
      typingTimeout: 0,
      isDisabled: 0
    }
  }

  handleClick = () => {
    history.push('/add')
  }

  handleSearchClick = (searchList) => {
    const { categoryDetails: { refetch } } = this.props
    let variables = {
      currentPage: 1,
      searchList: searchList
    }
    this.setState({ currentPage: 1 })
    refetch(variables)
  }

  handleSearchChange = (e) => {
    let self = this
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout)
    }
    self.setState({
      searchList: e.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleSearchClick(self.state.searchList)
      }, 450)
    })
  }

  handleDelete = async (id) => {
    const { deleteCategory, categoryDetails: { refetch, getAllCategory } } = this.props;
    const { currentPage } = this.state;
    let variables = { currentPage: currentPage > 1 && getAllCategory?.categoryData?.length == 1 ? currentPage - 1 : currentPage };
    this.setState({
      isDisabled: 1
    })
    const response = await deleteCategory(id);
    if (response && response?.status === 200) {
      this.setState({ currentPage: variables.currentPage });
      refetch(variables);
    }
    this.setState({
      isDisabled: 0
    })
  }

  paginationData = (currentPage) => {
    const { categoryDetails: { refetch } } = this.props;
    let variables = { currentPage };
    this.setState({ currentPage });
    refetch(variables);
  }

  handleStatus = async (e, id, currentPage) => {
    const { updateCategoryStatus, categoryDetails: { refetch } } = this.props;
    let status = e.target.value == 'true' ? '1' : '0';
    let variables = { currentPage };
    const response = await updateCategoryStatus(id, status);
    if (response && response?.status === 200) {
      this.setState({ currentPage });
      refetch(variables);
    }
  }

  handleSpecialStatus = async (e, id, privilegeType) => {
    const { updateCategoryPrivilege, categoryDetails: { refetch } } = this.props;
    const { currentPage } = this.state;
    let status = e.target.value == 'true' ? true : false;
    let variables = { currentPage };
    const response = await updateCategoryPrivilege(id, status, privilegeType);
    if (response && response.status === 200) {
      this.setState({ currentPage });
      refetch(variables);
    }
  }

  render() {
    const { categoryDetails } = this.props;
    const { formatMessage } = this.props.intl;
    const { currentPage, isDisabled } = this.state;
    return (
      <div className={cx(s.widthInner, s.tableSection, 'whiteDropdown', 'widthInnerNoBorder')}>
        <div className={cx(s.exportDisplay, s.tabelExport)}>
          <div>
            <div className={s.searchInput}>
              <FormControl type='text' placeholder={formatMessage(messages.search)} onChange={(e) => this.handleSearchChange(e)} className={s.formControlInput} />
            </div>
          </div>
          <div className={cx(s.exportTextSection, 'textAlignLeftRTL')}>
            <div className={cx(s.paddingTop2)}>
              <Link to={"/siteadmin/cuisine/add"} className={s.addlinkBtn}>{formatMessage(messages.addCategory)}</Link>
            </div>
          </div>
        </div>
        <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewAdminResponsiveTable')}>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.categoryName} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
                <th scope="col"><FormattedMessage {...messages.isTop} /></th>
                <th scope="col"><FormattedMessage {...messages.isMore} /></th>
                <th scope="col"><FormattedMessage {...messages.createdAt} /></th>
                <th scope="col"><FormattedMessage {...messages.action} /></th>
                <th scope="col"><FormattedMessage {...messages.deleteAction} /></th>
              </tr>
            </thead>
            <tbody>
              {
                categoryDetails?.getAllCategory?.categoryData && categoryDetails?.getAllCategory?.categoryData?.length > 0 && categoryDetails?.getAllCategory?.categoryData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item.id}</td>
                      <td data-label={formatMessage(messages.categoryName)}>{item.categoryName}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select value={item.isActive} onChange={(e) => { this.handleStatus(e, item.id, currentPage) }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.active)}</option>
                          <option value={false}>{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.isTop)}>
                        <select value={item.isTop} onChange={(e) => { this.handleSpecialStatus(e, item.id, 'isTop') }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.yes)}</option>
                          <option value={false}>{formatMessage(messages.no)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.isMore)}>
                        <select value={item.isMore} onChange={(e) => { this.handleSpecialStatus(e, item.id, 'isMore') }} className={s.selectInput}>
                          <option value={true}>{formatMessage(messages.yes)}</option>
                          <option value={false}>{formatMessage(messages.no)}</option>
                        </select>
                      </td>
                      <td data-label={formatMessage(messages.createdAt)}>{moment(item.createdAt).format('DD-MM-YYYY hh:mm A')}</td>
                      <td data-label={formatMessage(messages.action)}>
                        <Link to={'/siteadmin/cuisine/edit/' + item.id} className={'editAlignIcon'}>
                          <span><img src={EditIcon} className={cx(s.editIcon, 'editIconRTL')} /></span>
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.editAction} />
                          </span>
                        </Link>
                      </td>
                      <td className={s.trashPadding} data-label={formatMessage(messages.deleteAction)}>
                        <Button onClick={() => this.handleDelete(item.id, currentPage)} className={s.iconBtn} disabled={isDisabled}>
                          <img src={TrashIcon} className={cx(s.editIcon, 'editIconRTL')} />
                          <span className={s.vtrMiddle}>
                            <FormattedMessage {...messages.deleteAction} />
                          </span>
                        </Button>
                      </td>
                    </tr>
                  )
                })
              }
              {
                categoryDetails?.getAllCategory && categoryDetails?.getAllCategory?.categoryData?.length == 0 && (
                  <tr>
                    <td colSpan={50} className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </div>
        {
          categoryDetails?.getAllCategory?.categoryData && categoryDetails?.getAllCategory?.categoryData?.length > 0
          && <div className={cx(s.space5, s.spaceTop5)}>
            <CustomPagination
              total={categoryDetails?.getAllCategory?.count}
              currentPage={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              change={this.paginationData}
              paginationLabel={formatMessage(messages.categories)}
            />
          </div>
        }
      </div>
    );
  }
}

const mapDispatch = {
  updateCategoryStatus,
  deleteCategory,
  updateCategoryPrivilege
};
const mapState = (state) => ({});

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CategoryList)));