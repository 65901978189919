exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3kpJn {\n  font-size: 18px;\n  color: #9D7A62;\n}\n\n._19MGc {\n  font-size: 48px;\n}\n\n._3kpJn span {\n  -webkit-animation-name: _3uUYR;\n          animation-name: _3uUYR;\n  -webkit-animation-duration: 1.4s;\n          animation-duration: 1.4s;\n  -webkit-animation-iteration-count: infinite;\n          animation-iteration-count: infinite;\n  -webkit-animation-fill-mode: both;\n          animation-fill-mode: both;\n  color: #9D7A62;\n}\n\n._3kpJn span:nth-child(2) {\n  -webkit-animation-delay: .2s;\n          animation-delay: .2s;\n\n}\n\n._3kpJn span:nth-child(3) {\n  -webkit-animation-delay: .4s;\n          animation-delay: .4s;\n}\n\n@-webkit-keyframes _3uUYR {\n  0% {\n    opacity: .2;\n  }\n\n  20% {\n    opacity: 1;\n\n  }\n\n  100% {\n    opacity: .2;\n  }\n}\n\n@keyframes _3uUYR {\n  0% {\n    opacity: .2;\n  }\n\n  20% {\n    opacity: 1;\n\n  }\n\n  100% {\n    opacity: .2;\n  }\n}\n\n.FhA8T {\n  text-align: center;\n}\n\n._2isBS {\n  color: #9D7A62;\n  font-size: 48px;\n}\n\n._2z31c {\n  position: absolute;\n  top: 40%;\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n}", ""]);

// exports
exports.locals = {
	"saving": "_3kpJn",
	"savingDots": "_19MGc",
	"blink": "_3uUYR",
	"textCenter": "FhA8T",
	"iconColor": "_2isBS",
	"displayFlex": "_2z31c"
};