import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { injectIntl } from 'react-intl';
import Geosuggest from 'react-geosuggest';
import withStyles from 'isomorphic-style-loader/withStyles';
import messages from '../../../locale/messages';
import { googleApiLoader } from '../../../helpers/googleApiLoader';
import s from 'react-geosuggest/module/geosuggest.css';

class PlacesSuggest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      noResult: false,
      noInput: false,
      googleMapsApiLoaded: false
    };
  };

  async componentDidMount() {
    const autocompleteService = await googleApiLoader('places');
    this.setState({ googleMapsApiLoaded: true });
    this.autocompleteService = autocompleteService;
  }

  handleSelectSuggest = async (data) => {
    const { handleData } = this.props;
    this.setState({ noResult: false });
    handleData(data);
  };

  handleChange = async (input) => {
    this.setState({ noInput: !input })
  }

  handleBlur = async (input) => {
    this.setState({ noInput: !input })
  }

  render() {
    const { label, className, containerClassName, defaultValue, form } = this.props;
    const { noResult, noInput, googleMapsApiLoaded } = this.state;
    const { formatMessage } = this.props.intl;
    return (
      <div>
        {
          googleMapsApiLoaded ?
            <Geosuggest
              ref={el => this._geoSuggest = el}
              placeholder={label}
              inputClassName={className}
              initialValue={defaultValue}
              className={containerClassName}
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              onSuggestSelect={this.handleSelectSuggest}
              onSuggestNoResults={(value) => {
                value && this.setState({ noResult: true })
              }}
            />
            :
            <></>
        }

        {form == 'signup' ?
          noResult && <span className={'arrow_box'}>{formatMessage(messages.invalidAddress)}</span>
          :
          noResult && <span className={'placeError'}>{formatMessage(messages.invalidAddress)}</span>
        }

        {form == 'signup' ?
          noInput && <span className={'arrow_box'}>{formatMessage(messages.required)}</span>
          :
          noInput && <span className={'placeError'}>{formatMessage(messages.required)}</span>
        }
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  change
};

export default injectIntl(withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(PlacesSuggest)));
