exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1Vs4U {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #d4d7e5;\n}\n\n._2PW43 {\n  margin: 0 auto;\n}\n\n._Efz8 {\n  width: 100%;\n  max-width: 22px;\n  vertical-align: middle;\n  margin-right: 6px;\n}\n\n._3RdiA p {\n  margin: 0;\n  color: #3a3a3a;\n}\n\n.dp6Q1 {\n  text-align: right;\n}\n\n._3dYx1 {\n  text-align: left;\n}\n\n._39cCZ {\n  /* width: 100%; */\n  color: #484848 !important;\n  font-size: 14px;\n}\n\n.tdeWq {\n  display: inline-block;\n  width: 50%;\n  vertical-align: middle;\n  border-radius: 50%;\n  background-size: cover;\n  max-width: 100px;\n  min-height: 100px;\n  max-height: 100px;\n  padding: 0px 0px 0 0px;\n  margin-right: 18px;\n  border: 6px solid #F1F1F1;\n  margin-bottom: 0;\n  background-repeat: no-repeat;\n  background-color: #fff;\n  background-position: center;\n  z-index: 1;\n  position: relative;\n}\n\n._2umba {\n  opacity: 0.5;\n}\n\n._2ghwq {\n  display: inline-block;\n  width: 50%;\n  vertical-align: middle;\n  word-break: break-word;\n  margin: 0;\n}\n\n.ZoKD2 {\n  position: relative;\n}\n\n.ZoKD2::after {\n  content: \"\";\n  width: 6px;\n  height: 44%;\n  background: #F1F1F1;\n  position: absolute;\n  top: 95px;\n  left: 44px;\n  z-index: -1;\n  -webkit-box-shadow: 0 0 2px 1px #eeebeb;\n          box-shadow: 0 0 2px 1px #eeebeb;\n}\n\n._3y7BI {\n  display: block;\n  word-break: break-word;\n}\n\n._3DTyW {\n  color: #9D7A62;\n}\n\n._2aRZT {\n  border: 0px !important;\n}\n\n._26YrN {\n  margin-bottom: 50px;\n}\n\n._1gU-x {\n  display: inline-block;\n}\n\n._2claa {\n  width: 100%;\n  max-width: 18px;\n  vertical-align: middle;\n  margin-right: 8px;\n  margin-left: 3px;\n}\n\n._2qcbw {\n  font-size: 12px;\n  color: #767676;\n}\n\n@media screen and (max-width: 1204px) {\n  .ZoKD2::after {\n    content: \"\";\n    display: none;\n  }\n}\n\n@media screen and (max-width: 991px) {\n  .dp6Q1 {\n    text-align: left;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "_1Vs4U",
	"container": "_2PW43",
	"iconsWidth": "_Efz8",
	"itemprice": "_3RdiA",
	"IconRight": "dp6Q1",
	"IconLeft": "_3dYx1",
	"editBtn": "_39cCZ",
	"itemBgImg": "tdeWq",
	"inactive": "_2umba",
	"itemListContent": "_2ghwq",
	"itemBgImgBorder": "ZoKD2",
	"foodNameSection": "_3y7BI",
	"priceColor": "_3DTyW",
	"noborderImg": "_2aRZT",
	"marginBottomlistItem": "_26YrN",
	"displayInlineBlock": "_1gU-x",
	"activeImg": "_2claa",
	"subText": "_2qcbw"
};