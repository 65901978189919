import showToaster from '../../../helpers/showToaster';
import { deletePromoCodeMutation as mutation, getPromoCodes as query } from '../../lib/graphql';
import {
  DELETE_PROMOCODE_START,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_ERROR
} from '../../../constants';
import showErrorMessage from '../../../helpers/errorMessages/showErrorMessage';
import { setLoaderComplete, setLoaderStart } from '../../loader/loader';

export function deletePromoCode(id) {
  return async (dispatch, getState, { client }) => {

    try {
      dispatch({
        type: DELETE_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });

      dispatch(setLoaderStart('deletePromoCode'));
      const { data } = await client.mutate({
        mutation,
        variables: {
          id
        },
        refetchQueries: [
          {
            query,
            variables: {
              currentPage: 1
            }
          }
        ]
      });

      if (data?.deletePromoCode?.status === 200) {
        showToaster({ messageId: 'promoCodeDeleted', toasterType: 'success' })
        await dispatch({
          type: DELETE_PROMOCODE_SUCCESS,
          payload: {
            promoCodeLoading: false
          }
        });
        return { status: 200 }
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deletePromoCode?.errorMessage })

        await dispatch({
          type: DELETE_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: data?.deletePromoCode?.errorMessage
          }
        });
      }
      dispatch(setLoaderComplete('deletePromoCode'));
    } catch (error) {
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });

      await dispatch({
        type: DELETE_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error: await showErrorMessage({ errorCode: 'catchError', error })
        }
      });
      dispatch(setLoaderComplete('deletePromoCode'));
    }
  }
}