exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3-4jc {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n._25NbZ {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-content-width);\n}", ""]);

// exports
exports.locals = {
	"root": "_3-4jc",
	"container": "_25NbZ"
};