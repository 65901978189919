import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import { graphql } from 'react-apollo'
import { flowRight as compose } from 'lodash';

import ModifierGroupForm from '../../../components/Shop/ModifierGroups/ModifierGroupForm/ModifierGroupForm';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../not-found/NotFound';

import getModifier from './getModifier.graphql';

import s from './EditModifierGroups.css';
class EditModifierGroups extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  };

  static defaultProps = {
    data: {
      loading: true
    }
  }

  render() {

    const { data: { loading, getModifier } } = this.props;

    let initialValues = {};

    if (!loading && getModifier && getModifier.result) {
      initialValues = {
        id: getModifier.result.id,
        modifierName: getModifier.result.modifierName,
        modifierType: getModifier.result.modifierType,
        minModifierItems: getModifier.result.minModifierItems,
        maxModifierItems: getModifier.result.maxModifierItems,
        isModifierRequired: getModifier.result.isModifierRequired,
        isModifierMultiple: getModifier.result.isModifierMultiple,
        maxItemCount: getModifier.result.maxItemCount,
        isActive: getModifier.result.isActive,
        itemId: getModifier.result.itemId,
        clonedBy: getModifier.result.clonedBy,
        modifierItems: getModifier.result.modifierItems.length > 0 ? getModifier.result.modifierItems : [{
          modifierItemName: null,
          modifierItemPrice: null
        }]
      }
    }

    if (loading) {
      return <div><Loader type={"page"} show={loading}></Loader></div>
    } else if (!loading && !getModifier?.result) {
      return <NotFound title={'Page Not Found'} />
    } else {
      return (
        <div>
          <div>
            <ModifierGroupForm
              initialValues={initialValues}
            />
          </div>
        </div>
      )
    }
  }
}

export default compose(
  injectIntl,
  withStyles(s),
  graphql(getModifier, {
    options: (props) => ({
      variables: {
        id: props.id
      },
      fetchPolicy: 'network-only',
      ssr: false
    })
  }))(EditModifierGroups)
