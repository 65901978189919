import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { Modal, Button } from 'react-bootstrap';
import rs from '../../restaurantCommon.css';
import s from './ConfirmationPopup.css';
export class ConfirmationPopup extends Component {

	static defaultProps = {
		setShow: false
	}

	constructor(props) {
		super(props);
		this.state = {
			show: false,
			setShow: false
		};
	}

	componentDidMount() {
		const { modalStatus } = this.props;
		this.setState({ setShow: modalStatus ? true : false });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { modalStatus } = nextProps;
		this.setState({ setShow: modalStatus ? true : false });
	}

	render() {
		const { checkBookedModifier, closeModal, title, body, popupButtonLeftName, popupButtonRightName, popupButtonLeftFunction, popupButtonRightFunction, bodyModifier, orderTitle } = this.props;
		const { bodySubText, sideAdminPopUp } = this.props;
		const { setShow } = this.state;

		return (
			<div>
				<Modal show={setShow} onHide={closeModal} className={'listItemModal'}>
					<Modal.Header closeButton>
						<Modal.Title>{title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className={cx(rs.title)}>
						<p className={cx(orderTitle, rs.ModifierPopupText, s.space2)}>
							{checkBookedModifier && bodyModifier}
							{!checkBookedModifier && body}
						</p>
						{bodySubText && <p className={cx(rs.popUpDesc, s.space1)}>{bodySubText}</p>}
						</div>
						<div className={cx(rs.alignRightText, 'textAlignLeftRTL')}>
							<div className={cx(rs.displayInlineBlockRes, rs.btnMarginRight, 'btnMarginRightRTL')}>
								<Button variant="secondary" className={cx(rs.textOverFlow, rs.responsiveBtn, sideAdminPopUp ? s.btnSecondary : rs.btnPrimaryBorder )} onClick={() => popupButtonLeftFunction()}>
									{popupButtonLeftName}
								</Button>
							</div>
							<div className={rs.displayInlineBlockRes}>
								<Button variant="primary" className={cx(rs.textOverFlow, rs.responsiveBtn, sideAdminPopUp ? s.btnPrimary : rs.btnPrimary )} onClick={() => popupButtonRightFunction()}>
									{popupButtonRightName}
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}

const mapState = state => ({});

const mapDispatch = {};

export default injectIntl(withStyles(s, rs)(connect(mapState, mapDispatch)(ConfirmationPopup)));
