require('dotenv').config();
/* eslint-disable max-len */


module.exports = {
  // default locale is the first one
  locales: [
    /* @intl-code-template '${lang}-${COUNTRY}', */
    'en-US',
    'es',
    'fr-FR',
    'id-ID',
    'ja-JA',
    'ru-RU',
    'ar'
    /* @intl-code-template-end */
  ],

  port: process.env.PORT, // Node.js app

  trustProxy: process.env.TRUST_PROXY || 'loopback', // https://expressjs.com/en/guide/behind-proxies.html

  siteUrl: process.env.SITE_URL || 'https://www.plugd.net', // SITE URL

  api: { // API Gateway
    clientUrl: process.env.API_CLIENT_URL || '', // API URL to be used in the client-side code
    serverUrl: process.env.API_SERVER_URL || `http://localhost:${process.env.PORT || 3000}`,// API URL to be used in the server-side code
    apiEndpoint: process.env.API_ENDPOINT_URL || 'https://www.plugd.net/api'
  },

  socketUrl: process.env.SOCKET_URL || 'https://www.plugd.net', //Socket URL

  databaseUrl: process.env.DATABASE_URL, // Database

  // CRONs Timezone
  cronTimezone: process.env.CRON_TIMEZONE,
  // Refer this link for valid timezone https://raw.githubusercontent.com/node-cron/tz-offset/master/generated/offsets.json

  licenseuploadDir: process.env.LICENSE_UPLOAD_DIR, // Licence Upload

  vehicleUploadDir: process.env.VEHICLE_UPLOAD_DIR,  // Vehicle Upload

  profilePhotouploadDir: process.env.PROFILE_PHOTO_UPLOAD_DIR, // Profile photo upload

  categoryUploadDir: process.env.CATEGORY_PHOTO_UPLOAD_DIR, // category photo upload

  logoUploadDir: process.env.LOGO_PHOTO_UPLOAD_DIR, // Logo photo upload

  ogImageUploadDir: process.env.OG_IMAGE_UPLOAD_DIR, // OG Image Upload

  homepageUploadDir: process.env.HOMEPAGE_UPLOAD_DIR, // Home page photo upload

  staticpageUploadDir: process.env.STATICPAGE_UPLOAD_DIR, // Static page photo upload

  deliveryVehicleUploadDir: process.env.DELIVERY_VEHICLE_UPLOAD_DIR, // Delivery Vehicle Upload

  contentPageUploadDir: process.env.CONTENTPAGE_UPLOAD_DIR, // Contentpage photo upload

  restaurantImageUploadDir: process.env.RESTAURANT_IMAGE_UPLOAD_DIR, // RestaurantImage photo upload

  restaurantDocumentUploadDir: process.env.RESTAURANT_DOCUMENT_UPLOAD_DIR, // RestaurantImage photo upload

  partnerHomepageUploadDir: process.env.PARTNER_HOMEPAGE_UPLOAD_DIR, // Partner Home page Image photo upload

  itemImageUploadDir: process.env.ITEM_IMAGE_UPLOAD_DIR, // Restaurant Item Image

  faviconUploadDir: process.env.FAVICON_UPLOAD_DIR, // Favicon upload

  toneUploadDir: process.env.TONE_UPLOAD_DIR, // Order alert tone upload

  googleMapAPI: process.env.GOOGLE_MAP_API || 'AIzaSyDMG1RhPVziecRJqD60CjDzmgPKzTRP-K4', // Google map api key

  analytics: { // Web analytics https://analytics.google.com/
    googleTrackingId: process.env.GOOGLE_TRACKING_ID || 'UA-XXXXX-X', // UA-XXXXX-X
  },

  auth: { // Authentication
    jwt: { secret: process.env.JWT_SECRET },

    facebook: { // https://developers.facebook.com/
      id: process.env.FACEBOOK_APP_ID,
      secret: process.env.FACEBOOK_APP_SECRET,
    },

    google: { // https://cloud.google.com/console/project
      id: process.env.GOOGLE_CLIENT_ID,
      secret: process.env.GOOGLE_CLIENT_SECRET
    },

    twitter: { // https://apps.twitter.com/
      key: process.env.TWITTER_CONSUMER_KEY,
      secret: process.env.TWITTER_CONSUMER_SECRET,
    },
  },

};
