
import React from 'react';
import PayoutContainer from './PayoutContainer';
import { getPayouts } from '../../../../actions/shop/payout/getPayouts';
import DashboardLayout from '../../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../../locale/messages';

async function action({ store, query, intl }) {
  const title = intl.formatMessage(messages.payoutPreferences);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
  let currentAccountId = query && query.account;
  let country = query && query.country;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/shop' };
  }

  await store.dispatch(getPayouts(currentAccountId, country));

  return {
    title,
    component: (
      <DashboardLayout>
        <PayoutContainer title={title} />
      </DashboardLayout>
    )
  };

};

export default action;
