import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import ViewReview from './ViewReview';
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';

function action({ store, intl, params }) {
    const title = intl.formatMessage(messages.viewReview);

    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;

    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if (!restrictUrls('/siteadmin/reviews/view/', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }
    const id = Number(params.id);

    return {
        title,
        component: (
            <AdminLayout>
                <ViewReview title={title} id={id} isAdmin={isAdminAuthenticated} />
            </AdminLayout>
        )
    }
}

export default action;