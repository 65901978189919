exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._7jyqH {\n    width: 100%;\n    max-width: 1170px;\n    margin: 0 auto;\n}\n\n._16cPg {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-gap: 44px;\n}\n\n._3twQ5 {\n    text-align: center;\n    padding: 20px 20px 20px 20px;\n    -webkit-box-shadow: 6px 5px 20px 5px #eeedef;\n            box-shadow: 6px 5px 20px 5px #eeedef;\n    margin-bottom: 12px;\n}\n\n._3NzgB {\n    font-size: 18px;\n    font-weight: 500;\n    margin-bottom: 0px;\n    text-align: center;\n    padding-top: 12px;\n    color: var(--title-color);\n}\n\n._1sE-i {\n    font-size: 30px;\n    font-weight: 500;\n    color: var(--title-color);\n    margin-top: 0px;\n}\n\n._3ePbH {\n    font-size: 18px;\n    padding-bottom: 30px;\n    color: var(--content-color);\n}\n\n._2GKxG {\n    text-align: center;\n}\n\n._1JT3A {\n    margin-bottom: 30px;\n}\n\n._4AzkU {\n    padding-bottom: 80px;\n}\n\n._2rCdt {\n    width: 100%;\n    height: auto;\n}\n\n._3otqC {\n    display: block;\n    text-decoration: none !important;\n    color: #484848 !important;\n}\n\n._3otqC:hover {\n    color: #9D7A62 !important;\n}\n\n._1x6Dx {\n    margin-bottom: 18px;\n}\n\n@media (max-width: 1023px) and (min-width: 768px) {\n    ._16cPg {\n        grid-gap: 24px;\n    }\n\n    ._1sE-i {\n        font-size: 28px;\n    }\n\n    ._3twQ5 {\n        -webkit-box-shadow: 0px 0px 15px 2px #DCFFDA;\n                box-shadow: 0px 0px 15px 2px #DCFFDA;\n    }\n\n    ._3NzgB {\n        font-size: 16px;\n    }\n}\n\n@media screen and (max-width: 767px) {\n    ._16cPg {\n        grid-template-columns: repeat(1, 1fr);\n        grid-gap: 24px;\n    }\n\n    .Ei2nJ {\n        padding: 0px;\n    }\n\n    ._1sE-i {\n        font-size: 26px;\n    }\n\n    ._3ePbH {\n        font-size: 16px;\n    }\n}", ""]);

// exports
exports.locals = {
	"bannerLayoutContainer": "_7jyqH",
	"orderDisplayGrid": "_16cPg",
	"mainSection": "_3twQ5",
	"titleText": "_3NzgB",
	"headerText": "_1sE-i",
	"headerSubText": "_3ePbH",
	"textCenter": "_2GKxG",
	"headerMargin": "_1JT3A",
	"root": "_4AzkU",
	"partnerImage": "_2rCdt",
	"linkColor": "_3otqC",
	"marignBottom": "_1x6Dx",
	"responsiveNoPadding": "Ei2nJ"
};