import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import {
    Form,
    Col,
    Row,
    FormControl,
    FormGroup,
    Container
} from 'react-bootstrap';

import Loader from '../../Common/Loader';
import ImageUploadComponent from '../../ImageUploadComponent/ImageUploadComponent';

import messages from '../../../locale/messages';
import validate from './validate';
import { api, homepageUploadDir } from '../../../config';
import submit from './submit';

import s from './AboutSettingsForm.css';
export class AboutSettingsForm extends Component {

    renderField = ({ input, label, type, meta: { touched, error }, labelClass, fieldClass, placeholder }) => {
        const { formatMessage } = this.props.intl
        return (
            <Form.Group className={s.space3}>
                <Form.Label>{label}</Form.Label>
                <Form.Control {...input} placeholder={placeholder} type={type} className={cx(fieldClass, s.formControlInput)} />
                {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
            </Form.Group>
        )
    }

    renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
        const { formatMessage } = this.props.intl
        return (
            <div>
                <FormGroup className={s.space3}>
                    <div>
                        <label className={s.labelText} >{label}</label>
                    </div>
                    <div>
                        <FormControl
                            {...input}
                            className={className}
                            placeholder={label}
                            as="textarea"
                            rows="4"
                        >
                            {children}
                        </FormControl>
                        {touched && error && <span className={s.errorMessage}>{formatMessage(error)}</span>}
                    </div>
                </FormGroup>
            </div>
        );
    }

    render() {
        const { handleSubmit, aboutGridImage1, aboutGridImage2, aboutGridImage3, loading, submitting, aboutGridImage1Loader, aboutGridImage2Loader, aboutGridImage3Loader } = this.props;
        const { formatMessage } = this.props.intl;
        return (
            <div className={cx(s.space5, s.spaceTop5)}>
                <Container fluid>
                    <Row>
                        <Col lg={10} md={12} sm={12} xs={12} className={s.blackCenter}>
                            <div className={s.blackCenterSection}>
                                <Form className={s.fullWidth} onSubmit={handleSubmit(submit)}>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <h1 className={s.titleTextAdmin}>{formatMessage(messages.howItWorks)}</h1>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle5" type="text" component={this.renderField} label={formatMessage(messages.titleLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridContent5" type="text" component={this.renderFormControlTextArea} label={formatMessage(messages.descriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <ImageUploadComponent
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            loaderName={'aboutGridImage1Loader'}
                                                            postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                            loader={aboutGridImage1Loader}
                                                            fieldName={'aboutGridImage1'}
                                                            formName={'AboutSettingsForm'}
                                                            imageSrc={api.apiEndpoint + homepageUploadDir + aboutGridImage1}
                                                            label={formatMessage(messages.homeImage1)}
                                                            inputContainer={'.dzInputContainerAboutImage1'}
                                                            inputContainerClass={'dzInputContainerAboutImage1'}
                                                            isDefaultImg={aboutGridImage1 ? false : true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle1" type="text" component={this.renderField} label={formatMessage(messages.bannerImage1DescriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <ImageUploadComponent
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            fieldName={'aboutGridImage2'}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            inputContainer={'.dzInputContainerAboutImage2'}
                                                            inputContainerClass={'dzInputContainerAboutImage2'}
                                                            label={formatMessage(messages.homeImage2)}
                                                            imageSrc={api.apiEndpoint + homepageUploadDir + aboutGridImage2}
                                                            loaderName={'aboutGridImage2Loader'}
                                                            loader={aboutGridImage2Loader}
                                                            formName={'AboutSettingsForm'}
                                                            postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                            isDefaultImg={aboutGridImage2 ? false : true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle2" type="text" component={this.renderField} label={formatMessage(messages.bannerImage2DescriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col xl={4} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className={s.space3}>
                                                    <div className={cx(s.profileImgSection, s.profileImgWidth)}>
                                                        <ImageUploadComponent
                                                            className={cx(s.btnSecondary, s.profileNoPadding)}
                                                            subTextClass={s.subText}
                                                            fieldName={'aboutGridImage3'}
                                                            subText={formatMessage(messages.maximumUploadSizeLabel)}
                                                            defaultMessage={formatMessage(messages.chooseFile)}
                                                            inputContainer={'.dzInputContainerAboutImage3'}
                                                            inputContainerClass={'dzInputContainerAboutImage3'}
                                                            label={formatMessage(messages.homeImage3)}
                                                            imageSrc={api.apiEndpoint + homepageUploadDir + aboutGridImage3}
                                                            loaderName={'aboutGridImage3Loader'}
                                                            loader={aboutGridImage3Loader}
                                                            formName={'AboutSettingsForm'}
                                                            postUrl={api.apiEndpoint + '/uploadHomepageImage'}
                                                            isDefaultImg={aboutGridImage3 ? false : true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className={s.space3}>
                                                    <div>
                                                        <Field name="aboutGridTitle3" type="text" component={this.renderField} label={formatMessage(messages.bannerImage3DescriptionLabel)} />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12} className={cx(s.textAlignRight, s.spaceTop1, 'textAlignLeftRTL')}>
                                                <Form.Group className={s.noMargin}>
                                                    <div className={s.displayInlineBlock}>
                                                        <Loader
                                                            type={"button"}
                                                            label={formatMessage(messages.submitButton)}
                                                            show={loading}
                                                            buttonType={'submit'}
                                                            className={cx(s.button, s.btnPrimary, 'rtlBtnLoader')}
                                                            disabled={submitting || loading}
                                                            isSuffix={true}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

AboutSettingsForm = reduxForm({
    form: 'AboutSettingsForm',
    onSubmit: submit,
    validate
})(AboutSettingsForm);

const selector = formValueSelector('AboutSettingsForm')

const mapState = (state) => ({
    aboutGridImage1: selector(state, 'aboutGridImage1'),
    aboutGridImage2: selector(state, 'aboutGridImage2'),
    aboutGridImage3: selector(state, 'aboutGridImage3'),
    loading: state.loader.AboutSettingsForm,
    aboutGridImage1Loader: state.loader.aboutGridImage1Loader,
    aboutGridImage2Loader: state.loader.aboutGridImage2Loader,
    aboutGridImage3Loader: state.loader.aboutGridImage3Loader
})

const mapDispatch = {

}

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(AboutSettingsForm)));