
const showArrow = ({ data }) => {
    try {
        let showArrow = false;
        const isBrowser = typeof window !== 'undefined';

        if (isBrowser) {
            if (window.matchMedia('(max-width: 575px)').matches) {
                showArrow = data?.result?.length > 1 ? true : false
            } else if (window.matchMedia('(min-width:576px) and (max-width: 991px)').matches) {
                showArrow = data?.result?.length > 2 ? true : false
            } else if (window.matchMedia('(min-width: 992px) and (max-width: 1198px)').matches) {
                showArrow = data?.result?.length > 3 ? true : false
            } else {
                showArrow = data?.result?.length > 4 ? true : false
            }
        }
        return showArrow;
    } catch (error) {
        return false;
    }
}

export default showArrow;
