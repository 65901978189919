import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';

import { api, homepageUploadDir } from '../../../config';


import s from './HomeBanner.css';

class RestaurantBanner extends React.Component {
    constructor(props) {
        super(props);
    }

    scrollTop = () => {
        window.scrollTo({
            top: screen.height,
            behavior: 'smooth'
        })
    }

    render() {
        const { data } = this.props;
        let homeSettings = {};

        data?.homePageData?.map((item) => {
            homeSettings[item.name] = item.value
        });

        return (
            <div className={cx(s.layout4Container)}>
                <div className={cx(s.bannerBackgroundImage)}
                    style={{ backgroundImage: `url(${api.apiEndpoint + homepageUploadDir + homeSettings?.homeSectionImage1})` }} />
                <Container className={cx(s.bannerLayoutContainer)}>
                    <div className={cx(s.bannerDisplayGrid, 'align-items-center')}>
                        <div className={s.searchFormContainer}>
                            <div className={s.searchFormWrap}>
                                <h1>
                                    {homeSettings?.homeSectionTitle1}
                                </h1>
                                <p>
                                    {homeSettings?.homeSectionDescription}
                                </p>
                            </div>
                        </div>

                        <div className={cx(s.bannerImageWrapper)}>
                            <img src={api.apiEndpoint + homepageUploadDir + homeSettings?.homeSectionImage2} className={s.plateImage} />
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

const mapState = (state) => ({});

const mapDispatch = {};

export default withStyles(s)(connect(mapState, mapDispatch)(RestaurantBanner));