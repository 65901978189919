import axios from 'axios';
import { pushNotificationMessage } from './pushNotificationMessage';
import { siteUrl } from '../../config';
import { getShortPushNotification } from './getShortPushNotification';

export async function sendNotifications(
  type,
  requestContent,
  userId,
  userType,
  lang,
) {
  let content = {};

  if (type !== 'notification') {
    const { title, message } = await pushNotificationMessage(
      type,
      requestContent,
      lang,
    );
    const trimContent = await getShortPushNotification(message);
    content = requestContent;
    content.title = title;
    content.message = trimContent || message;
  } else {
    content = requestContent;
  }

    const response = await axios.post(`${siteUrl}/push-notification`, {
        content,
        userId,
        userType
    }, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        withCredentials: true
    });

    const { status, errorMessage } = await response?.data;

  return await {
    status,
    errorMessage,
  };
}

export default sendNotifications;
