import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { getPaymentMethods as query } from '../../lib/graphql';
import {
  ADD_PAYOUT_START,
  ADD_PAYOUT_SUCCESS,
  ADD_PAYOUT_ERROR,
} from '../../../constants';

export function checkPayout() {

  return async (dispatch, getState, { client }) => {

    try {

      dispatch({
        type: ADD_PAYOUT_START
      });

      const { data } = await client.query({
        query,
        fetchPolicy: 'network-only'
      });

      if (data?.getPaymentMethods?.length > 0) {
        if (!data?.getPaymentMethods[0]?.isEnable) {

          dispatch({
            type: ADD_PAYOUT_ERROR
          });

          showToaster({ messageId: 'featureNotAvailable', toasterType: 'warning' })
          return;
        } else {
          dispatch({
            type: ADD_PAYOUT_SUCCESS
          });
          history.push('/shop/addpayout');
        }
      }

    } catch (error) {
      dispatch({
        type: ADD_PAYOUT_ERROR
      });
    }
  };
};