exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3Bm9a {\n\tborder-radius: 0;\n\twidth: 100%;\n\tdisplay: block;\n\tcolor: #484848;\n\tfont-size: 16px;\n\tpadding-bottom: 12px;\n\tborder-bottom: 1px solid #dce0e0;\n}\n\n._1g4Iq {\n\tdisplay: block;\n\tpadding: 8px 10px !important;\n\twidth: 100%;\n}\n\n._1MteI {\n\ttext-align: right;\n}\n\n._1RGho {\n\tmargin-bottom: 6px;\n}\n\n._3c4Fn {\n\tfont-size: 22px;\n\ttext-align: center;\n\tmargin-bottom: 20px;\n}\n\n._2hcXF {\n\twidth: 100%;\n\tmax-width: 140px;\n}\n\n.UpK5J {\n\ttext-align: center;\n}\n\n._3A3Ty {\n\tmargin-bottom: 25px;\n}\n\n.Zx1og {\n\twidth: 96%;\n\tmargin: 0 2%;\n}\n\n._3dp4O {\n\tpadding-left: 20px;\n\tpadding-right: 20px;\n\twidth: 100%;\n}\n\n._3T981 {\n\tmargin: 50px auto;\n\tmax-width: 420px;\n\tborder: 1px solid #ccc;\n\tpadding: 20px;\n}\n\n@media (max-width: 991px) and (min-width: 768px) {\n\t._3dp4O {\n\t\tmin-height: 440px;\n\t}\n}\n\n@media screen and (max-width: 767px) {\n\t._1MteI {\n\t\ttext-align: left;\n\t}\n}", ""]);

// exports
exports.locals = {
	"panelHeader": "_3Bm9a",
	"formControlInput": "_1g4Iq",
	"textRight": "_1MteI",
	"formGroup": "_1RGho",
	"heading": "_3c4Fn",
	"saveBtnWidth": "_2hcXF",
	"textAlignCenter": "UpK5J",
	"marginBottom": "_3A3Ty",
	"btnWidth": "Zx1og",
	"root": "_3dp4O",
	"container": "_3T981"
};