
import React from 'react';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import OrderManageSection from './OrderManageSection';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.orderManageMent);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/shop' };
  };

  const type = params.type;

  return {
    title,
    component: <DashboardLayout>
      <OrderManageSection title={title} type={type} />
    </DashboardLayout>

  };
}

export default action;
