exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3BMoN {\n  color: red\n}\n\n._3i9yK {\n  margin-top: 0;\n  font-size: 30px;\n  color: var(--title-color);\n}\n\n._38PcC {\n  font-weight: 500;\n  font-size: 16px;\n  margin-bottom: 18px;\n  color: var(--title-color);\n}\n\n._2Maw6 {\n  display: inline-block !important;\n}\n\n._1XNJs {\n  width: 15%;\n}\n\n._1N6WQ {\n  width: 85%;\n}\n\n._2o3U_ {\n  width: 25%;\n}\n\n._1A7ug {\n  position: relative;\n  display: inline-block;\n  float: right;\n  width: 50%;\n}\n\n._14FTH {\n  padding-left: 8px;\n}\n\n._1gq4p {\n  vertical-align: middle;\n}\n\n._2rCn3 {\n  float: none;\n  display: inline-block;\n  width: 50%;\n  padding: 5px;\n}\n\n.DzfHk {\n  width: 100%;\n  display: block;\n  margin-top: 0px;\n}\n\n.VhBB1 {\n  border: 0px !important;\n}\n\n.tC3KI {\n  color: var(--title-color) !important;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.iJ2Ao {\n  display: block;\n  position: absolute;\n  width: 100%;\n  left: 0;\n  right: 0;\n  width: 100%;\n  padding: 0 6px;\n  margin-top: 3px;\n}\n\n._2rCn3 label {\n  font-weight: 400;\n}\n\n.PViNF {\n  vertical-align: top;\n}\n\n@media (max-width: 1200px) and (min-width: 992px) {\n  ._1XNJs {\n    width: 25%;\n  }\n\n  ._1N6WQ {\n    width: 75%;\n  }\n\n  ._2o3U_ {\n    width: 50%;\n  }\n}\n\n@media screen and (max-width: 1199px) {\n  ._18X8Q {\n    font-size: 14px;\n  }\n}\n\n@media screen and (max-width: 991px) {\n  ._1XNJs {\n    width: 100%;\n  }\n\n  ._1N6WQ {\n    width: 100%;\n  }\n\n  ._2o3U_ {\n    width: 100%;\n  }\n\n  ._1A7ug {\n    display: inline-block;\n    float: right;\n    width: 100%;\n  }\n}", ""]);

// exports
exports.locals = {
	"errorMessage": "_3BMoN",
	"titleText": "_3i9yK",
	"daysText": "_38PcC",
	"displayInlineblock": "_2Maw6",
	"switchSection": "_1XNJs",
	"radioBtnSection": "_1N6WQ",
	"radioBtnLabelSection": "_2o3U_",
	"timeSection": "_1A7ug",
	"paddingLeft": "_14FTH",
	"vtrMiddle": "_1gq4p",
	"timeInline": "_2rCn3",
	"tableText": "DzfHk",
	"noBorder": "VhBB1",
	"labelTitleText": "tC3KI",
	"errorMessageSection": "iJ2Ao",
	"vtrTop": "PViNF",
	"selectText": "_18X8Q"
};