exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/** RentAll - Begin **/\n\n._3Vqxt {\n\tmax-width: 1024px;\n\tmargin: 54px auto;\n\twidth: 100%;\n\tpadding: 10px;\n}\n\n.m4o2M {\n\tpadding: 0px;\n\tmargin: 30px 15px;\n}\n\n._2jh6D {\n\ttext-align: center;\n\twidth: 100%;\n\tmargin-top: 5px;\n}\n\n._3TBPz {\n\tfont-size: 22px;\n\tfont-weight: 600;\n\tmargin: 0;\n}\n\n@media screen and (max-width: 767px) {\n\t._3Vqxt {\n\t\tmargin: 0px auto;\n\t}\n}", ""]);

// exports
exports.locals = {
	"logInModalContainer": "_3Vqxt",
	"logInModalBody": "m4o2M",
	"textAlignCenter": "_2jh6D",
	"headerH2": "_3TBPz"
};