
import React from 'react';
import EditFoodItem from './EditFoodItem';
import DashboardLayout from '../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import editItem from '../../../actions/shop/menu/editItem';
import messages from '../../../locale/messages';

async function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.itemSettings);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/shop' };
  };

  const id = Number(params.id);

  await store.dispatch(editItem(id));

  return {
    title,
    component: (
      <DashboardLayout>
        <EditFoodItem title={title} />
      </DashboardLayout>
    ),
  };
}

export default action;
