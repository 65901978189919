import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import Swiper from 'react-id-swiper';
import HomeItem from '../HomeItem';
import Loader from '../../Common/Loader';
import { isRTL } from '../../../helpers/formatLocale';
import nextArrow from '../../../../public/Icons/Right-arow.png';
import prevArrow from '../../../../public/Icons/Left-arrow.png';
import s from './HomeSlider.css';
import showArrow from './showArrow';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={nextArrow} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img src={prevArrow} />
    </div>
  );
}

class HomeSlider extends React.Component {
  static defaultProps = {
    data: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
    this.swiper = null;
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
  }

  componentDidMount() {
    this.setState({
      load: true
    });
  }

  UNSAFE_componentWillReceiveProps(prevProps) {
    const { locale } = this.props.intl;
    const { locale: prevLocale } = prevProps.intl;

    if (locale !== prevLocale) {
      this.setState({
        load: false
      });
      clearTimeout(this.loadSync);
      this.loadSync = null;
      this.loadSync = setTimeout(() => {
        this.setState({
          load: true
        })
      }, 100);
    }
  }

  goNext() {
    this.swiper.slideNext();
  }

  goPrev() {
    this.swiper.slidePrev();
  }


  render() {
    const { data, intl: { locale }, updatedLocale } = this.props;
    const { load } = this.state;
    let params = {};

    const isShowArrow = showArrow({ data })

    params = {
      slidesPerView: 1,
      loop: false,
      spaceBetween: 12,
      breakpoints: {
        320: {
          slidesPerView: 1,
          grabCursor: true
        },
        576: {
          slidesPerView: 2,
          grabCursor: true
        },
        992: {
          slidesPerView: 3,
        },
        1023: {
          slidesPerView: 3,
        },
        1199: {
          slidesPerView: 3,
        },
        1439: {
          slidesPerView: 4,
        },
        1699: {
          slidesPerView: 4,
        }
      }
    };

    if (isShowArrow == true) {
      params['loop'] = true;
    }

    return (
      <div className={s.root}>
        <div className={cx('homeSlickSlider', 'homeSlickSliderContainer')}>
          {
            !load && <div>
              <Loader type="text" />
            </div>
          }
          {
            !updatedLocale && <div className={s.positionRelative}>
              <Swiper {...params} ref={node => this.swiper = node !== null ? node.swiper : null} className={cx('row', s.noMargin)} >
                {
                  data.result.map((item, index) => {
                    return (
                      <div key={index}>
                        <div>
                          <HomeItem
                            key={index}
                            categoryName={item.categoryName}
                            categoryImage={item.categoryImage}
                          />
                        </div>
                      </div>
                    );
                  })}
              </Swiper>
              {isShowArrow && <div className={s.arrowPosition}>
                <SamplePrevArrow className={cx(s.prevArrow, 'prevArrowRTL')} onClick={this.goPrev} />
                <SampleNextArrow className={cx(s.nextArrow, 'nextArrowRTL')} onClick={this.goNext} />
              </div>}
            </div>}
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  updatedLocale: state?.intl?.newLocale,
});
const mapDispatch = {};
export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(HomeSlider)));

