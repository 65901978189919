import showToaster from '../../../helpers/showToaster';
import history from '../../../history';
import { updateForgotPassword as mutation } from '../../lib/graphql';

export function updatePassword(email, newPassword, token) {

  return async (dispatch, getState, { client }) => {
    let errorMessage;
    try {

      const { data } = await client.mutate({
        mutation,
        variables: {
          email,
          token,
          newPassword
        }
      });

      if (data?.updateForgotPassword) {
        if (data?.updateForgotPassword?.status === 200) {
          showToaster({ messageId: 'changePasswordSuccess', toasterType: 'success' })
          history.push('/shop');
        } else {
          errorMessage = data?.updateForgotPassword?.errorMessage
          showToaster({ messageId: 'changePasswordFailed', toasterType: 'error', requestContent: errorMessage })
          return false;
        }
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' });
        return false;
      }

    } catch (error) {
      showToaster({ messageId: 'changePasswordFailed', toasterType: 'error', requestContent: error })
      return false;
    }

    return true;
  };
}