import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './EditMenu.css';
import AddMenuForm from '../../../components/Shop/ManageMenu/AddMenu/AddMenuForm'
import { graphql } from 'react-apollo'
import { flowRight as compose } from 'lodash';
import getMenu from './getMenu.graphql';
import Loader from '../../../components/Common/Loader/Loader';

class EditMenu extends React.Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
  };

  static defaultProps = {
    data: {
      loading: true
    }
  }

  render() {
    const { data: { loading, getMenu }, data, id } = this.props;

    let initialValues = {};

    if (!loading && getMenu && getMenu.result) {
      initialValues = {
        id: getMenu.result.id,
        menuName: getMenu.result.menuName,
        menuAvailability: getMenu.result.menuAvailability,
        isEnable: getMenu.result.isEnable,
      }
    }

    return (
      <Loader type={"page"} show={loading}>
        <div className={s.root}>
          <div className={s.container}>
            {!loading && <AddMenuForm initialValues={initialValues} />}
          </div>
        </div>
      </Loader>
    );
  }
}

export default compose(withStyles(s), graphql(getMenu, {
  options: (props) => ({
    variables: {
      id: props.id
    },
    fetchPolicy: 'network-only',
    ssr: false
  })
}))(EditMenu)
