exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".hprJC {\n  padding-left: 20px;\n  padding-right: 20px;\n  padding-top: 100px;\n  padding-bottom: 30px;\n  text-align: center;\n}\n\n.m2ZQS {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-content-width);\n}\n\n.tuuq- {\n  width: 100%;\n  max-width: 450px;\n  margin: 0 auto;\n}\n\n._1WF-K {\n  display: block;\n  max-width: 200px;\n  margin: 0 auto;\n}\n\n.QEGN3 {\n  font-size: 30px;\n  font-weight: 700;\n}\n\n._1Qm8E {\n  font-size: 18px;\n  font-weight: 500;\n}", ""]);

// exports
exports.locals = {
	"root": "hprJC",
	"container": "m2ZQS",
	"notFoundImg": "tuuq-",
	"btnWidth": "_1WF-K",
	"notFoundTitle": "QEGN3",
	"notFoundSubTitle": "_1Qm8E"
};