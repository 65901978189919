import showToaster from '../../../helpers/showToaster';
import { updateShopProfileMutation as mutation } from '../../lib/graphql';
import { setLoaderStart, setLoaderComplete } from '../../loader/loader';
import { loadAccount } from '../userAccount/userAccount';
import {
    SHOP_PROFILE_UPDATE_ERROR,
    SHOP_PROFILE_UPDATE_START,
    SHOP_PROFILE_UPDATE_SUCCESS
} from '../../../constants';

export default function updateShopProfile(values) {
    return async (dispatch, getState, { client }) => {
        let errorMessage;

        try {
            dispatch({
                type: SHOP_PROFILE_UPDATE_START
            });

            dispatch(setLoaderStart('RestaurantProfileUpdate'));

            const { data } = await client.mutate({
                mutation,
                variables: {
                    shopName: values?.shopName,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    email: values?.email,
                    phoneDialCode: values?.phoneDialCode,
                    phoneNumber: values?.phoneNumber,
                    phoneCountryCode: values?.phoneCountryCode,
                    address: values?.address,
                    lat: values?.lat,
                    lng: values?.lng,
                    city: values?.city,
                    state: values?.state,
                    zipcode: values?.zipcode,
                    country: values?.country,
                    categoryType: values?.categoryType,
                    description: values?.description,
                    priceRangeId: values?.priceRange,
                    picture: values?.picture,
                    preferredCurrency: values?.preferredCurrency,
                    dietaryType: values?.dietaryType,
                    shopEstimatedTime: values?.shopEstimatedTime,
                    isTakeAway: values?.isTakeAway,
                    isDoorDelivery: values?.isDoorDelivery,
                    shopPickupInstruction: values?.shopPickupInstruction
                }
            });

            if (data?.updateShopProfile?.status == 200) {
                showToaster({ messageId: 'profileUpdated', toasterType: 'success' });
                await dispatch({
                    type: SHOP_PROFILE_UPDATE_SUCCESS
                });
                await dispatch(setLoaderComplete('RestaurantProfileUpdate'));
                setTimeout(() => {
                    dispatch(loadAccount())
                }, 1000);

            } else {
                errorMessage = data?.updateShopProfile?.errorMessage;
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })

                await dispatch({
                    type: SHOP_PROFILE_UPDATE_ERROR
                });
                dispatch(setLoaderComplete('RestaurantProfileUpdate'));
            }
        } catch (error) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });

            await dispatch({
                type: SHOP_PROFILE_UPDATE_ERROR
            });
            dispatch(setLoaderComplete('RestaurantProfileUpdate'));
        };
    };
};