exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1LQ5- {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n._1s7Xi {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-content-width);\n}\n\n._1sfRe {\n  font-size: 44px;\n  font-weight: 600;\n}\n\n.v9395 {\n  font-size: 30px;\n  margin-bottom: 24px;\n}\n\n._3Gsto {\n  margin-right: 10px;\n}\n\n@media screen and (max-width: 991px) {\n  .I88BA {\n    width: 100%;\n  }\n\n  ._3Gsto {\n    margin-right: 0px;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "_1LQ5-",
	"container": "_1s7Xi",
	"textJumbo": "_1sfRe",
	"subText": "v9395",
	"firstBtn": "_3Gsto",
	"bntWidth": "I88BA"
};