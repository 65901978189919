exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1avl7 {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n._3YPN- {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-content-width);\n}\n\n.-EKGZ {\n  margin: 0 0 32px;\n  margin: 0 0 2rem;\n}\n\n._3GwAp {\n  font-size: 24px;\n  font-size: 1.5rem;\n}\n\n._107uo h1,\n._107uo h2,\n._107uo h3,\n._107uo h4,\n._107uo h5,\n._107uo h6 {\n  font-size: 18px;\n  font-size: 1.125rem;\n}\n\n._107uo pre {\n  white-space: pre-wrap;\n  font-size: 14px;\n  font-size: 0.875rem;\n}\n\n._107uo img {\n  max-width: 100%;\n}\n\n._2WUi2 {\n  font-size: 0.8em;\n  color: #777;\n}\n\n@media screen and (max-width: 767px) {\n  .YWlIy {\n    overflow: hidden;\n  }\n}", ""]);

// exports
exports.locals = {
	"root": "_1avl7",
	"container": "_3YPN-",
	"newsItem": "-EKGZ",
	"newsTitle": "_3GwAp",
	"newsDesc": "_107uo",
	"publishedDate": "_2WUi2",
	"overFlowHidden": "YWlIy"
};