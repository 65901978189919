import messages from '../../../locale/messages';
import { inputTextLimit, inputDescLimit } from '../../../helpers/fieldRestriction';

const validate = values => {

  const errors = {}

  if (!values.pushNotificationKey || values.pushNotificationKey.toString().trim() == "") {
    errors.pushNotificationKey = messages.required;
  } else if (values.pushNotificationKey.length > inputTextLimit) {
    errors.pushNotificationKey = messages.inputTextLimitError;
  }

  if (!values.smtpHost || values.smtpHost.toString().trim() == "") {
    errors.smtpHost = messages.required;
  } else if (values.smtpHost.length > inputTextLimit) {
    errors.smtpHost = messages.inputTextLimitError;
  }

  if (!values.smtpPort || values.smtpPort.toString().trim() == "") {
    errors.smtpPort = messages.required;
  } else if (values.smtpPort && (isNaN(values.smtpPort) || Number(values.smtpPort) <= 0)) {
    errors.smtpPort = messages.floatError;
  } else if (values.smtpPort && Number(values.smtpPort) > 65535) {
    errors.smtpPort = messages.portError;
  }

  if (!values.smptEmail || values.smptEmail.toString().trim() == "") {
    errors.smptEmail = messages.required && messages.required;
  } else if (values.smptEmail.length > inputTextLimit) {
    errors.smptEmail = messages.inputTextLimitError;
  }

  if (!values.smtpSender || values.smtpSender.toString().trim() == "") {
    errors.smtpSender = messages.required;
  } else if (values.smtpSender.length > inputTextLimit) {
    errors.smtpSender = messages.inputTextLimitError;
  }

  if (!values.smtpPassWord || values.smtpPassWord.toString().trim() == "") {
    errors.smtpPassWord = messages.required;
  } else if (values.smtpPassWord.length > inputTextLimit) {
    errors.smtpPassWord = messages.inputTextLimitError;
  }


  if (!values.smtpSenderEmail) {
    errors.smtpSenderEmail = messages.required && messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.smtpSenderEmail)) {
    errors.smtpSenderEmail = messages.emailInvalid && messages.emailInvalid;
  }

  if (!values.twilioAccountSid || values.twilioAccountSid.toString().trim() == "") {
    errors.twilioAccountSid = messages.required;
  } else if (values.twilioAccountSid.length > inputTextLimit) {
    errors.twilioAccountSid = messages.inputTextLimitError;
  }

  if (!values.twilioAuthToken || values.twilioAuthToken.toString().trim() == "") {
    errors.twilioAuthToken = messages.required;
  } else if (values.twilioAuthToken.length > inputTextLimit) {
    errors.twilioAuthToken = messages.inputTextLimitError;
  }

  if (!values.twilioPhone || values.twilioPhone && values.twilioPhone.toString().trim() == "") {
    errors.twilioPhone = messages.required && messages.required;
  } else if (values.twilioPhone.length > 30) {
    errors.twilioPhone = messages.phoneNumberLengthInvalid;
  }

  if (!values.stripePublishableKey || values.stripePublishableKey.toString().trim() == "") {
    errors.stripePublishableKey = messages.required;
  } else if (values.stripePublishableKey.length > inputTextLimit) {
    errors.stripePublishableKey = messages.inputTextLimitError;
  }

  if (!values.maxUploadSize || values.maxUploadSize.toString().trim() == "") {
    errors.maxUploadSize = messages.required;
  } else if (values.maxUploadSize && (isNaN(values.maxUploadSize) || Number(values.maxUploadSize) <= 0)) {
    errors.maxUploadSize = messages.invalid;
  }

  if (!values.maxDistance || values.maxDistance.toString().trim() == "") {
    errors.maxDistance = messages.required;
  } else if (values.maxDistance && (isNaN(values.maxDistance) || Number(values.maxDistance) <= 0)) {
    errors.maxDistance = messages.invalid;
  }

  if (!values.subtractTime || values.subtractTime.toString().trim() == "") {
    errors.subtractTime = messages.required;
  } else if (values.subtractTime) {
    if (isNaN(values.subtractTime)) {
      errors.subtractTime = messages.invalid;
    } else if (Number(values.subtractTime) < 10) {
      errors.subtractTime = messages.subratctTimeInvalid;
    }
  }

  if (!values.fcmPushNotificationKey || values.fcmPushNotificationKey && values.fcmPushNotificationKey.toString().trim() == "") {
    errors.fcmPushNotificationKey = messages.required;
  } else if (values.fcmPushNotificationKey.length > inputDescLimit) {
    errors.fcmPushNotificationKey = messages.inputDescLimitError;
  }

  if (!values.fcmApiKey || values.fcmApiKey.toString().trim() == "") {
    errors.fcmApiKey = messages.required;
  } else if (values.fcmApiKey.length > inputTextLimit) {
    errors.fcmApiKey = messages.inputTextLimitError;
  }

  if (!values.fcmAuthDomain || values.fcmAuthDomain.toString().trim() == "") {
    errors.fcmAuthDomain = messages.required;
  } else if (values.fcmAuthDomain.length > inputTextLimit) {
    errors.fcmAuthDomain = messages.inputTextLimitError;
  }

  if (!values.fcmProjectId || values.fcmProjectId.toString().trim() == "") {
    errors.fcmProjectId = messages.required && messages.required;
  } else if (values.fcmProjectId.length > inputTextLimit) {
    errors.fcmProjectId = messages.inputTextLimitError;
  }

  if (!values.fcmStorageBucket || values.fcmStorageBucket.toString().trim() == "") {
    errors.fcmStorageBucket = messages.required;
  } else if (values.fcmStorageBucket.length > inputTextLimit) {
    errors.fcmStorageBucket = messages.inputTextLimitError;
  }

  if (!values.fcmMessagingSenderId || values.fcmMessagingSenderId.toString().trim() == "") {
    errors.fcmMessagingSenderId = messages.required;
  } else if (values.fcmMessagingSenderId.length > inputTextLimit) {
    errors.fcmMessagingSenderId = messages.inputTextLimitError;
  }

  if (!values.fcmAppId || values.fcmAppId.toString().trim() == "") {
    errors.fcmAppId = messages.required;
  } else if (values.fcmAppId.length > inputTextLimit) {
    errors.fcmAppId = messages.inputTextLimitError;
  }

  if (!values.fcmMeasurementId || values.fcmMeasurementId.toString().trim() == "") {
    errors.fcmMeasurementId = messages.required;
  } else if (values.fcmMeasurementId.length > inputTextLimit) {
    errors.fcmMeasurementId = messages.inputTextLimitError;
  }

  if (!values.fcmVapidKey || values.fcmVapidKey.toString().trim() == "") {
    errors.fcmVapidKey = messages.required;
  } else if (values.fcmVapidKey.length > inputTextLimit) {
    errors.fcmVapidKey = messages.inputTextLimitError;
  }

  if (!values.stripeSecretKey || values.stripeSecretKey.toString().trim() == "") {
    errors.stripeSecretKey = messages.required;
  } else if (values.stripeSecretKey.length > inputTextLimit) {
    errors.stripeSecretKey = messages.inputTextLimitError;
  }
  return errors;
}

export default validate;
