var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Wq7b {\n\tmargin-bottom: 6px;\n}\n\n._2a963 {\n\toverflow: hidden;\n\tfont-size: 19px;\n\tmargin-top: 35px;\n}\n\n._3sLTu {\n\ttext-align: right;\n}\n\n._3cpQ1 {\n\ttext-align: left;\n}\n\n._3GJjI {\n\tcolor: var(--btn-secondary-bg);\n}\n\n._8HZJh {\n\tfont-size: 16px;\n\tline-height: 36px;\n}\n\n._3GJjI:hover,\n._3GJjI:focus {\n\tcolor: var(--btn-secondary-bg);\n\ttext-decoration: underline;\n}\n\n._36JmP {\n\tbackground: url(" + escape(require("./mail.png")) + ") no-repeat;\n\tbackground-position: 98% 50%;\n\tpadding: 10px 35px 10px 10px;\n}\n\n._1aVMh {\n\tcolor: #9D7A62 !important;\n\tmargin-right: 8px;\n}\n\n._1Pfa5 {\n\tfont-weight: bold;\n\tfont-size: 15px;\n\tmargin-bottom: 8px;\n\tmargin-top: 20px;\n}\n\n._3U4d9 {\n\twidth: 100%;\n\tdisplay: table;\n}\n\n._1yPHB {\n\tvertical-align: top;\n\tdisplay: table-cell;\n}\n\n._1EKs8 {\n\twidth: 15px;\n\tposition: relative;\n\ttop: 2px;\n}\n\n@media screen and (max-width: 767px) {\n\n\t._37HtM {\n\t\t-ms-flex-direction: column-reverse;\n\t\t    flex-direction: column-reverse;\n\t}\n\n\t._2cFCw {\n\t\ttext-align: center;\n\t}\n\n\t._2a963 {\n\t\tmargin-top: 20px;\n\t}\n\n\t.WERbz {\n\t\tmargin-top: 12px;\n\t\tdisplay: -ms-flexbox;\n\t\tdisplay: flex;\n\t\t-ms-flex-pack: center;\n\t\t    justify-content: center;\n\t}\n\n\t._3GJjI {\n\t\tmargin-left: 10px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"formGroup": "_2Wq7b",
	"formSection": "_2a963",
	"textAlignRight": "_3sLTu",
	"textAlignLeft": "_3cpQ1",
	"modalCaptionLink": "_3GJjI",
	"modalCaptionLinkLarge": "_8HZJh",
	"meassageicon": "_36JmP",
	"leftICon": "_1aVMh",
	"forgetEmail": "_1Pfa5",
	"displayTable": "_3U4d9",
	"displayTableCell": "_1yPHB",
	"leftArrow": "_1EKs8",
	"mobileColumn": "_37HtM",
	"textCenterMobile": "_2cFCw",
	"mobileFlex": "WERbz"
};