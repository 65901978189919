
import React from 'react';
import FailedPayoutContainer from './FailedPayoutContainer';
import DashboardLayout from '../../../../components/Shop/RestaurantLayout/Layout/DashboardLayout';
import messages from '../../../../locale/messages';

async function action({ store, query, intl }) {
  const title = intl.formatMessage(messages.payoutPreferences);

  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
  let currentAccountId = query && query.account;

  if (!isRestaurantAuthenticated) {
    return { redirect: '/shop' };
  }

  return {
    title,
    component: (
      <DashboardLayout>
        <FailedPayoutContainer title={title} currentAccountId={currentAccountId} />
      </DashboardLayout>
    )
  };

};

export default action;
