import fetch from 'node-fetch';
import showToaster from '../../../helpers/showToaster';

export default async function submit(values, dispatch) {
    const mutation = `
        mutation updateServiceFee(
            $id: Int,
            $feeType: String,
            $bookingFee: Int,
            $shopFee: Int,
            $driverFee: Int,
            $deliveryFee: Float,
            $dropFee: Float,
            $distanceFee: Float
        ) {
            updateServiceFee(
                id: $id,
                feeType: $feeType
                bookingFee: $bookingFee,
                shopFee: $shopFee,
                driverFee: $driverFee,
                deliveryFee: $deliveryFee,
                dropFee: $dropFee,
                distanceFee: $distanceFee
            ) {
                status
                errorMessage
            }
        }
    `;

    const response = await fetch('/graphql', {
        method: 'post',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: mutation,
            variables: {
                id: values.id,
                feeType: values.feeType,
                bookingFee: values.bookingFee,
                shopFee: values.shopFee,
                driverFee: values.driverFee,
                deliveryFee: values.deliveryFee,
                dropFee: values.dropFee,
                distanceFee: values.distanceFee
            }
        }),
        credentials: 'include'
    });

    const { data } = await response.json();

    if(data?.updateServiceFee?.status === 200) {
        showToaster({ messageId: 'serviceUpdated', toasterType: 'success' });
    } else {
        showToaster({ messageId: 'updateServiceFailed', toasterType: 'error' })
    }
}