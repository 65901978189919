import messages from '../../../../locale/messages';
import { inputDescLimit, inputTextLimit } from '../../../../helpers/fieldRestriction';

const validate = values => {
    const errors = {};

    if (!values.categoryName) {
        errors.categoryName = messages.required;
    } else if (values.categoryName && values.categoryName.trim().toString() == "") {
        errors.categoryName = messages.required;
    } else if(values.categoryName.length > inputTextLimit) {
        errors.categoryName = messages.inputTextLimitError;
    }

    if (!values.description) {
        errors.description = messages.required;
    } else if (values.description && values.description.trim().toString() == "") {
        errors.description = messages.required;
    } else if (values.description.length > inputDescLimit) {
        errors.description = messages.inputDescLimitError;
    }

    return errors;
};

export default validate