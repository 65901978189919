import history from '../../history';
import showToaster from '../../helpers/showToaster';
import { getContentPageDetails as query, deleteContentPage as mutation, updateContentPageStatusMutation } from '../lib/graphql';
import {
    ADMIN_DELETE_CONTENTPAGE_START,
    ADMIN_DELETE_CONTENTPAGE_SUCCESS,
    ADMIN_DELETE_CONTENTPAGE_ERROR
} from '../../constants';

const deleteContentPageDetails = (id) => {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: ADMIN_DELETE_CONTENTPAGE_START,
                data: id
            });

            const { data } = await client.mutate({
                mutation,
                variables: { id },
                refetchQueries: [{ query }]
            });

            if (data?.deleteContentPage?.status == 200) {
                dispatch({
                    type: ADMIN_DELETE_CONTENTPAGE_SUCCESS
                });
                showToaster({ messageId: 'deleteContentPage', toasterType: 'success' });
                history.push('/siteadmin/contentpage/manage');
            } else {
                showToaster({ messageId: 'deleteContentPageFailed', toasterType: 'error' });
            }

        } catch (error) {
            dispatch({
                type: ADMIN_DELETE_CONTENTPAGE_ERROR,
                payload: {
                    error
                }
            });
        }
    };
};

const updateContentPageStatus = (id, isEnable) => {
    return async (dispatch, getState, { client }) => {
        try {
            const { data } = await client.mutate({
                mutation: updateContentPageStatusMutation,
                variables: { id, isEnable },
                refetchQueries: [{ query }]
            });

            if (data?.updateContentPageStatus?.status == 200) {
                showToaster({ messageId: 'statusChanged', toasterType: 'success' });
            } else {
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' });
            }
        } catch (error) {
            showToaster({ messageId: 'statusFailed', toasterType: 'error' });
        }
    };
};

export {
    updateContentPageStatus,
    deleteContentPageDetails
}