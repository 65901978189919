import { change } from 'redux-form';
import showToaster from '../../helpers/showToaster';
import { siteSettings } from './siteSettings';
import { updateSiteSettingsMutation, updateTone } from '../lib/graphql';
import { removeImage } from '../../helpers/removeImage';
import {
    UPDATE_SITE_SETTINGS_START,
    UPDATE_SITE_SETTINGS_ERROR,
    UPDATE_SITE_SETTINGS_SUCCESS,
    TONE_UPLOAD_START,
    TONE_UPLOAD_SUCCESS,
    TONE_UPLOAD_ERROR
} from '../../constants/index';

export default function updateSiteSettings(siteName, siteTitle, metaDescription, facebookLink, twitterLink, instagramLink, metaKeyword, homeLogo, youtubeLink, currency, distanceUnits, appForceUpdate, eaterAndroidVersion, eaterIosVersion, driverAndroidVersion, driverIosVersion, preferredDelivery, favicon, contactNumber, contactEmail, contactSkype, notificationInterval, requestTimeTone, requestToneFile, orderAcceptInterval ,ogImage) {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: UPDATE_SITE_SETTINGS_START
            });
            const { data } = await client.mutate({
                mutation: updateSiteSettingsMutation,
                variables: {
                    siteName,
                    siteTitle,
                    metaDescription,
                    facebookLink,
                    twitterLink,
                    instagramLink,
                    metaKeyword,
                    homeLogo,
                    youtubeLink,
                    currency,
                    distanceUnits,
                    appForceUpdate,
                    eaterAndroidVersion,
                    eaterIosVersion,
                    driverAndroidVersion,
                    driverIosVersion,
                    preferredDelivery,
                    favicon,
                    contactNumber,
                    contactEmail,
                    contactSkype,
                    notificationInterval,
                    requestTimeTone,
                    requestToneFile,
                    orderAcceptInterval,
                    ogImage
                }
            });

            if (data?.updateSiteSettings?.status == 200) {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_SUCCESS
                });
                await dispatch(siteSettings());
                showToaster({ messageId: 'siteSettingChangesApplied', toasterType: 'success' });
            } else {
                dispatch({
                    type: UPDATE_SITE_SETTINGS_ERROR
                });
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' });
            }
        } catch (err) {
            dispatch({
                type: UPDATE_SITE_SETTINGS_ERROR,
            });
            showToaster({ messageId: 'somethingWentWrong', toasterType: 'error' });
        }
    };
};

export function deleteTone(fileName) {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: TONE_UPLOAD_START
            })
            dispatch(change("SiteSettingsForm", "requestTimeTone", null));

            let url = "/deleteTone";
            const status = await removeImage(url, fileName);

            if (status == 200) {
                const { data } = await client.mutate({
                    mutation: updateTone
                });
                dispatch({
                    type: TONE_UPLOAD_SUCCESS
                });
            } else {
                dispatch({
                    type: TONE_UPLOAD_ERROR
                });
            }

        } catch (err) {
            dispatch({
                type: TONE_UPLOAD_ERROR
            });
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err });
            return true;
        }
    };
};
