exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1fQuF {\n  padding-left: 20px;\n  padding-right: 20px;\n  min-height: calc(100vh - 100px);\n}\n\n._1FCft {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-content-width);\n}", ""]);

// exports
exports.locals = {
	"root": "_1fQuF",
	"container": "_1FCft"
};