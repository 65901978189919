exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26t1d {\n    width: 100%;\n    height: 550px;\n    background: #fff no-repeat;\n    background-position: 50% 50%;\n    background-size: cover;\n}\n\n._2gvEZ {\n    width: 100%;\n    text-align: center;\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-pack: center;\n        justify-content: center;\n    -ms-flex-align: center;\n        align-items: center;\n    padding: 0px 0 80px 0;\n}\n\n/* .city{\n    background: #F9F8F2;\n    padding: 60px 0 0 0 0;\n} */\n\n._29uSE h1 {\n    letter-spacing: 1px;\n    word-spacing: 2px;\n    position: relative;\n    font-size: 30px;\n    font-weight: 500;\n    color: var(--title-color);\n    margin-top: 0px;\n}\n\n._29uSE p {\n    width: 100%;\n    max-width: 950px;\n    margin: 0 auto;\n    letter-spacing: 1px;\n    word-spacing: 2px;\n    padding-top: 18px;\n    font-size: 18px;\n    color: var(--content-color);\n}\n\n._3g2Wv {\n    padding: 0px;\n}\n\n.jrnMv {\n    width: 100%;\n    max-width: 1170px;\n    margin: 0 auto;\n}", ""]);

// exports
exports.locals = {
	"carBg": "_26t1d",
	"cityBg": "_2gvEZ",
	"cityText": "_29uSE",
	"noPadding": "_3g2Wv",
	"containerWidth": "jrnMv"
};