import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout'
import EditStaticPage from './EditStaticPage'
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';

function action({ store, params, intl }) {
    const title = intl.formatMessage(messages.editPageDetails);

    let id = Number(params.pageId)
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if (!restrictUrls('/siteadmin/staticpage/edit/', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }
    return {
        title,
        component: (
            <AdminLayout>
                <EditStaticPage title={title} id={id} isAdmin />
            </AdminLayout>
        )
    }
}

export default action;