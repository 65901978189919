exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".s-s8P {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-gap: 44px;\n}\n\n._2eteO {\n    text-align: center;\n    padding: 30px 30px 30px 30px;\n    border: 1px solid #dedede;\n    border-radius: 4px;\n}\n\n._52Enf {\n    font-size: 18px;\n    font-weight: 500;\n    margin-bottom: 0px;\n    color: var(--title-color);\n    text-align: center;\n    margin-top: 12px;\n}\n\n._1k1kl {\n    font-size: 30px;\n    font-weight: 500;\n    color: var(--title-color);\n    margin-top: 0px;\n}\n\n._2SIgl {\n    font-size: 18px;\n    padding-bottom: 30px;\n    color: var(--content-color);\n}\n\n._2Ozf9 {\n    text-align: center;\n}\n\n._11v0u {\n    margin-bottom: 60px;\n}\n\n._2Ct3a {\n    padding-bottom: 80px;\n}\n\n.B6Myj {\n    width: 100%;\n    height: auto;\n}\n\n._3P7W6 {\n    padding: 0px;\n}\n\n._37Nsk {\n    padding-top: 74%;\n    background-repeat: no-repeat;\n    background-size: cover;\n    width: 100%;\n    height: 100%;\n    background-color: #fff;\n}\n\n@media (max-width: 1023px) and (min-width: 768px) {\n    ._2eteO {\n        padding: 20px 20px;\n    }\n\n    .s-s8P {\n        grid-gap: 6px;\n    }\n\n    ._1k1kl {\n        font-size: 28px;\n    }\n\n    ._11v0u {\n        margin-bottom: 30px;\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .s-s8P {\n        grid-template-columns: repeat(1, 1fr);\n        grid-gap: 24px;\n    }\n\n    ._11v0u {\n        margin-bottom: 30px;\n    }\n\n    ._2lI-n {\n        padding: 0px;\n    }\n\n    ._1k1kl {\n        font-size: 26px;\n    }\n\n    ._2SIgl {\n        font-size: 16px;\n    }\n}", ""]);

// exports
exports.locals = {
	"orderDisplayGrid": "s-s8P",
	"mainSection": "_2eteO",
	"titleText": "_52Enf",
	"headerText": "_1k1kl",
	"headerSubText": "_2SIgl",
	"textCenter": "_2Ozf9",
	"headerMargin": "_11v0u",
	"root": "_2Ct3a",
	"partnerImage": "B6Myj",
	"noPadding": "_3P7W6",
	"bannerBackgroundImage": "_37Nsk",
	"responsiveNoPadding": "_2lI-n"
};