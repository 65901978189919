import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import EditCategoryForm from '../../../components/SiteAdmin/Category/EditCategoryForm';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../not-found/NotFound';

import getCategory from './getCategory.graphql';
import messages from '../../../locale/messages';

import s from './EditCategory.css';
export class EditCategory extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }
    static defaultProps = {
        data: {
            loading: true
        }
    }
    render() {
        const { title, data: { loading, getCategory }, data, id, isAdmin } = this.props;

        let initialValues = {};

        if (!loading && getCategory && getCategory.category) {
            initialValues = {
                id: getCategory.category.id,
                categoryName: getCategory.category.categoryName,
                categoryImage: getCategory.category.categoryImage,
                isActive: getCategory.category.isActive,
                description: getCategory.category.description
            }
        }

        if (!loading && !getCategory?.category) {
            return <NotFound title={'Page Not Found'} isAdmin={isAdmin} />
        } else {
            return (
                <Loader type={"page"} show={loading}>
                    <div className={s.root}>
                        <div className={s.container}>
                            <div className={s.heading}>
                                <FormattedMessage {...messages.EditCuisine} />
                            </div>
                            <div>
                                {!loading && <EditCategoryForm initialValues={initialValues} />}
                            </div>
                        </div>
                    </div>
                </Loader>
            )
        }

    }
}

export default compose(withStyles(s), graphql(getCategory, {
    options: (props) => ({
        variables: {
            id: props.id
        },
        fetchPolicy: 'network-only',
        ssr: false
    })
}))(EditCategory)
