
import React from 'react';
import Layout from '../../../components/Shop/RestaurantLayout/Layout/Layout';
import RestaurantHome from './RestaurantHome';
import messages from '../../../locale/messages';

function action({ store, intl }) {
  const title = intl.formatMessage(messages.restaurants);
  let isRestaurantAuthenticated = store.getState().runtime.isRestaurantAuthenticated;
  if (isRestaurantAuthenticated) {
    return { redirect: '/shop/dashboard' };
  };

  return {
    title,
    component: (
      <Layout>
        <RestaurantHome title={title} />
      </Layout>
    ),
  };
}

export default action;
