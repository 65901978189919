import messages from '../../../locale/messages';
import { inputDescLimit, inputTextLimit } from '../../../helpers/fieldRestriction';

const validate = values => {
    const errors = {};

    if (!values?.shopName) {
        errors.shopName = messages.required;
    } else if (values?.shopName?.trim() == "") {
        errors.shopName = messages.required;
    } else if (values?.shopName?.length > inputTextLimit) {
        errors.shopName = messages.inputTextLimitError;
    };

    if (!values?.firstName) {
        errors.firstName = messages.required;
    } else if (values?.firstName?.trim() == "") {
        errors.firstName = messages.required;
    } else if (values?.firstName?.length < 2) {
        errors.firstName = messages.required
    } else if (values?.firstName?.length > inputTextLimit) {
        errors.firstName = messages.inputTextLimitError;
    };

    if (!values?.lastName) {
        errors.lastName = messages.required;
    } else if (values?.lastName?.trim() == "") {
        errors.lastName = messages.required;
    } else if (values?.lastName?.length < 2) {
        errors.lastName = messages.required
    } else if (values?.lastName?.length > inputTextLimit) {
        errors.lastName = messages.inputTextLimitError;
    };

    if (!values?.description) {
        errors.description = messages.required;
    } else if (values?.description?.trim() == "") {
        errors.description = messages.required;
    } else if (values?.description?.length < 4) {
        errors.description = messages.required
    } else if (values?.description?.length > inputDescLimit) {
        errors.description = messages.inputDescLimitError;
    };

    if (!values?.email) {
        errors.email = messages.required;
    } else if (!values?.email?.includes('@')) {
        errors.email = messages.emailInvalid
    } else if (!/^(([^<>()[\]\\.,;.!-#$&%*+/=?:{|}~-\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values?.email)) {
        errors.email = messages.emailInvalid
    } else if (values?.email?.length < 4) {
        errors.email = messages.required
    } else if (values?.email?.length > inputTextLimit) {
        errors.email = messages.inputTextLimitError;
    };

    if (!values?.zipcode) {
        errors.zipcode = messages.required;
    } else if (values?.zipcode?.trim() == "") {
        errors.zipcode = messages.required;
    } else if (values?.zipcode?.length < 4) {
        errors.zipcode = messages.required
    } else if (values?.zipcode?.length > inputTextLimit) {
        errors.zipcode = messages.inputTextLimitError;
    };

    if (!values?.phoneNumber) {
        errors.phoneNumber = messages.required;
    } else if (isNaN(values?.phoneNumber)) {
        errors.phoneNumber = messages.required;
    } else if (values?.phoneNumber?.trim() == "") {
        errors.phoneNumber = messages.required;
    } else if (values?.phoneNumber?.length < 4) {
        errors.phoneNumber = messages.required
    } else if (values?.phoneNumber?.length > inputTextLimit) {
        errors.phoneNumber = messages.inputTextLimitError;
    };

    if (!values?.preferredCurrency) {
        errors.preferredCurrency = messages.required;
    }

    if (!values?.priceRange) {
        errors.priceRange = messages.required;
    }

    if (!values?.dietaryType) {
        errors.dietaryType = messages.required;
    }


    if (!values?.address) {
        errors.address = messages.required;
    } else if (values?.address?.trim() == "") {
        errors.address = messages.required;
    }

    if (values?.categoryType?.length == 0) {
        errors.categoryType = messages.required;
    }

    if (!values?.isTakeAway && !values?.isDoorDelivery) {
        errors.isDoorDelivery = messages.validateDietary;
    }

    if (values?.isTakeAway && (!values.shopPickupInstruction || !values.shopPickupInstruction.trim())) {
        errors.shopPickupInstruction = messages.required;
    } else if (values?.shopPickupInstruction?.length > 500) {
        errors.shopPickupInstruction = messages.takeAwayLimitError;
    }

    return errors;
};

export default validate