import React from 'react';
import { Table } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { updatePaymentMethod } from "../../../actions/siteadmin/Payout/updatePayout";
import { openConfirmModal } from '../../../actions/siteadmin/modalActions';
import messages from '../../../locale/messages';

import bt from '../../../components/commonStyle.css';
import s from './PaymentGatewayList.css';
class PaymentGatewayList extends React.Component {

  constructor(props) {
    super(props);
  }

  handleChange = async (e, id) => {
    const { updatePaymentMethod, openConfirmModal } = this.props;
    let status = e.target.value == 'true' ? true : false;
    if (status) {
      await updatePaymentMethod(id, status);
    } else {
      await openConfirmModal({ name: 'isConfirmModalOpen', status: true, data: { id, status } });
    }
  }

  render() {
    const { getPaymentMethods, isConfirmModalOpen, modalData, updatePaymentMethod, openConfirmModal } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div className={cx(s.widthInner, 'whiteDropdown', 'bgBlackTwo', s.paymentInner, "managePaymentTableContainer")}>
        <ConfirmationPopup
          modalStatus={isConfirmModalOpen}
          title={formatMessage(messages.changePayment)}
          body={formatMessage(messages.changePaymentConfirmLabel)}
          bodySubText={formatMessage(messages.changePaymentConfirmDescription)}
          closeModal={() => openConfirmModal({ name: 'isConfirmModalOpen', status: false })}
          popupButtonLeftName={formatMessage(messages.cancelButton)}
          popupButtonRightName={formatMessage(messages.confirm)}
          popupButtonLeftFunction={() => openConfirmModal({ name: 'isConfirmModalOpen', status: false })}
          popupButtonRightFunction={() => updatePaymentMethod(modalData?.id, modalData?.status)}
          sideAdminPopUp
        />
        <div class="clearfix"></div>
        <div className={cx('tableCss', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable', 'paymentTable')}>
          <Table className={cx("table", 'topScrollbarTableContent')}>
            <thead>
              <tr>
                <th scope="col"><FormattedMessage {...messages.id} /></th>
                <th scope="col"><FormattedMessage {...messages.paymentGateWay} /></th>
                <th scope="col"><FormattedMessage {...messages.status} /></th>
              </tr>
            </thead>
            <tbody>
              {
                getPaymentMethods && getPaymentMethods.results && getPaymentMethods.results.length > 0 && getPaymentMethods.results.map((item, index) => {
                  let status = item.isEnable ? 'true' : 'false';
                  return (
                    <tr key={index}>
                      <td data-label={formatMessage(messages.id)}>{item && item.id}</td>
                      <td data-label={formatMessage(messages.paymentGateWay)}>{item && item.paymentName}</td>
                      <td data-label={formatMessage(messages.status)}>
                        <select name="isEnable" onChange={(e) => this.handleChange(e, item.id)} className={s.selectInput} value={status}>
                          <option value="true">{formatMessage(messages.active)}</option>
                          <option value="false">{formatMessage(messages.inactive)}</option>
                        </select>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}
const mapState = (state) => ({
  modalData: state.modalStatus.data && state.modalStatus.data[0],
  isConfirmModalOpen: state.modalStatus.isConfirmModalOpen,
});

const mapDispatch = {
  updatePaymentMethod,
  openConfirmModal
};

export default compose(injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch)
)(PaymentGatewayList);
