import io from 'socket.io-client';
import { socketUrl } from '../../config';

async function socketNotification(eventName, eventData) {
    const secure = socketUrl && socketUrl.indexOf('https://') >= 0 ? true : false;
    const socket = io.connect(socketUrl, {
        secure,
        upgrade: false,
        transports: ['websocket'],
        reconnection: false,
        forceNew: false,
        rejectUnauthorized: false
    });
    socket.on('connect', function () {
        if (eventName && eventData) {
            socket.emit(eventName, eventData);
        }
    });
}

export async function sendSocketInformation({ socketName, socketData }) {
    socketNotification(socketName, JSON.stringify({
        data: socketData
    }));
}

export default socketNotification;
