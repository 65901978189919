import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';

import EditContentPageForm from '../../../../components/SiteAdmin/ContentPage/EditContentPageForm';
import Loader from '../../../../components/Common/Loader/Loader';
import NotFound from '../../../not-found/NotFound';

import editContentPage from './editContentPage.graphql';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

import s from './EditContentPage.css';
export class EditContentPage extends Component {

  static propTypes = {
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }

  render() {
    const { title, editContentPageData: { loading, editContentPage }, isAdmin } = this.props;

    if (!loading && !editContentPage) {
      return <NotFound title={'Page Not Found'} isAdmin />
    } else {
      return (
        <Loader type={"page"} show={loading}>
          <div className={s.root}>
            <div className={s.container}>
              <div className={s.heading}>
                <FormattedMessage {...messages.EditContentDetails} />
              </div>
              {!loading && <div>
                <EditContentPageForm
                  title={title} initialValues={editContentPage}
                />
              </div>}
            </div>
          </div>
        </Loader>
      )
    }


  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default compose(
  withStyles(s),
  graphql(editContentPage, {
    name: 'editContentPageData',
    options: (props) => ({
      variables: {
        id: props.id,
      },
      fetchPolicy: 'network-only',
      ssr: false
    })
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(EditContentPage)