import gql from "graphql-tag";

export const addCancelReasonMutation = gql`
mutation(
    $userType: Int, 
    $reason: String, 
    $isActive: Boolean) {
    addCancelReason(
        userType: $userType, 
        reason: $reason, 
        isActive: $isActive
        ) {
        status
        errorMessage
    }
}`;

export const updateCancelReasonMutation = gql`
mutation(
    $id: Int, 
    $userType: Int, 
    $reason: String, 
    $isActive: Boolean
    ) {
    updateCancelReason(
        id: $id, 
        userType: $userType, 
        reason: $reason, 
        isActive: $isActive
        ) {
        status
        errorMessage
    }
}`;

export const getAllCancelReason = gql`
query getAllCancelReason ($searchList: String $currentPage: Int) {
    getAllCancelReason(searchList: $searchList currentPage: $currentPage) {
      count
      result{
        id
        reason
        userType
        isActive
      }
      status
    }
}`;

export const removeCancelReason = gql`
mutation($id: Int) {
    removeCancelReason(id: $id) {
        status
        errorMessage
    }
}`;

export const getContentPageDetails = gql`
query getContentPageDetails {
    getContentPageDetails{
      id
      metaTitle
      metaDescription
      pageUrl
      pageTitle
      content
      isEnable
      createdAt
    }
  }
`;

export const deleteContentPage = gql`
mutation deleteContentPage ($id: Int!) {
    deleteContentPage (id: $id) {
        status
    }
}
`;

export const updateContentPageStatusMutation = gql`
mutation updateContentPageStatus ($id: Int, $isEnable: Boolean) {
    updateContentPageStatus(id: $id, isEnable: $isEnable){
        status
    }
}
`;

export const getSiteSettings = gql`
query getSiteSettings($type:String){
    getSiteSettings(type:$type){
    name
    value
    }
}`;

export const updateFavIconMutation = gql`
mutation updateFavIcon(
    $favicon: String
    ) {
        updateFavIcon(
         favicon: $favicon
        ){
            status
            errorMessage
        }
    }
`;

export const updateHomePageAbout = gql`
mutation updateHomePageAbout (
    $aboutGridImage1: String
    $aboutGridImage2: String
    $aboutGridTitle1: String
    $aboutGridTitle2: String
    $aboutGridTitle3: String
    $aboutGridTitle4: String
    $aboutGridTitle5: String
    $aboutGridTitle6: String
    $aboutGridContent1: String
    $aboutGridContent2: String
    $aboutGridContent3: String
    $aboutGridContent4: String
    $aboutGridContent5: String
    $aboutGridContent6: String,
    $aboutGridImage3: String,
  ) {
    updateHomePageAbout (
      aboutGridImage1: $aboutGridImage1
      aboutGridImage2: $aboutGridImage2
      aboutGridTitle1: $aboutGridTitle1
      aboutGridTitle2: $aboutGridTitle2
      aboutGridTitle3: $aboutGridTitle3
      aboutGridTitle4: $aboutGridTitle4
      aboutGridTitle5: $aboutGridTitle5
      aboutGridTitle6: $aboutGridTitle6
      aboutGridContent1: $aboutGridContent1
      aboutGridContent2: $aboutGridContent2
      aboutGridContent3: $aboutGridContent3
      aboutGridContent4: $aboutGridContent4
      aboutGridContent5: $aboutGridContent5
      aboutGridContent6: $aboutGridContent6,
      aboutGridImage3: $aboutGridImage3
      
    ) {
      status
    }
  }
`;

export const updateHomePageCity = gql`
mutation updateHomePageCity (
    $citySectionTitle1: String
    $citySectionContent1: String
  ) {
    updateHomePageCity (
      citySectionTitle1: $citySectionTitle1
      citySectionContent1: $citySectionContent1
    ) {
      status
    }
  }
`;

export const updateHomePageDriver = gql`
mutation updateHomePageDriver (
    $safetyGridImage4: String
    $safetyGridImage5: String
    $safetyGridImage6: String
    $safetyGridTitle2: String
    $safetyGridContent2: String
    $safetyGridLink3: String
    $safetyGridLink4: String
  ) {
    updateHomePageDriver (
        safetyGridImage4: $safetyGridImage4
        safetyGridImage5: $safetyGridImage5
        safetyGridImage6: $safetyGridImage6
        safetyGridTitle2: $safetyGridTitle2
        safetyGridContent2: $safetyGridContent2
        safetyGridLink3: $safetyGridLink3
        safetyGridLink4: $safetyGridLink4
    ) {
      status
    }
  }
`;

export const updateHomePageFooter = gql`
mutation updateHomePageFooter (
    $footerLogo1: String
    $footerLogo2: String
    $footerLogo3: String
    $footerLogo4: String
    $footerTitle1: String
    $footerContent1: String
    $footerLink1: String
    $footerLink2: String
    $footerLink3: String
    $footerLink4: String
    $footerLinkName1: String
    $footerLinkName2: String
    $footerLinkName3: String
    $footerLinkName4: String
    $footerLinkTitle: String
    $footerBottom: String
  ) {
    updateHomePageFooter (
      footerLogo1: $footerLogo1
      footerLogo2: $footerLogo2
      footerLogo3: $footerLogo3
      footerLogo4: $footerLogo4
      footerTitle1: $footerTitle1
      footerContent1: $footerContent1
      footerLink1: $footerLink1
      footerLink2: $footerLink2
      footerLink3: $footerLink3
      footerLink4: $footerLink4
      footerLinkName1: $footerLinkName1
      footerLinkName2: $footerLinkName2
      footerLinkName3: $footerLinkName3
      footerLinkName4: $footerLinkName4
      footerLinkTitle: $footerLinkTitle
      footerBottom: $footerBottom
    ) {
      status
    }
  }
`;

export const updateHomePageHome = gql`
mutation updateHomePageHome (
    $homeSectionImage1: String
    $homeSectionImage2: String
    $homeSectionImage3: String
    $homeSectionImage4: String
    $homeSectionImage5: String
    $homeSectionImage6: String
    $homeSectionDescription: String
    $homeSectionTitle1: String
  ) {
    updateHomePageHome (
      homeSectionImage1: $homeSectionImage1
      homeSectionImage2: $homeSectionImage2
      homeSectionImage3: $homeSectionImage3
      homeSectionImage4: $homeSectionImage4
      homeSectionImage5: $homeSectionImage5
      homeSectionImage6: $homeSectionImage6
      homeSectionDescription: $homeSectionDescription
      homeSectionTitle1: $homeSectionTitle1
    ) {
      status
    }
  }
`;

export const updateHomePageSafety = gql`
mutation updateHomePageSafety (
    $safetyGridImage1: String
    $safetyGridImage2: String
    $safetyGridImage3: String
    $safetyGridTitle1: String
    $safetyGridContent1: String
    $safetyGridLink1: String
    $safetyGridLink2: String
  ) {
    updateHomePageSafety (
      safetyGridImage1: $safetyGridImage1
      safetyGridImage2: $safetyGridImage2
      safetyGridImage3: $safetyGridImage3
      safetyGridTitle1: $safetyGridTitle1
      safetyGridContent1: $safetyGridContent1
      safetyGridLink1: $safetyGridLink1
      safetyGridLink2: $safetyGridLink2
    ) {
      status
    }
  }
`

export const updateHomePageSignup = gql`
mutation updateHomePageSignup (
    $signupGridImage1: String
    $signupGridImage2: String
    $signupGridImage3: String
    $signupGridTitle1: String
    $signupGridContent1: String
    $signupGridLink1: String
    $signupGridLink2: String
  ) {
    updateHomePageSignup (
      signupGridImage1: $signupGridImage1
      signupGridImage2: $signupGridImage2
      signupGridImage3: $signupGridImage3
      signupGridTitle1: $signupGridTitle1
      signupGridContent1: $signupGridContent1
      signupGridLink1: $signupGridLink1
      signupGridLink2: $signupGridLink2
    ) {
      status
    }
  }
`;

export const removeStaticPage = gql`
mutation removeStaticPage(
    $id: Int,
  ) {
    removeStaticPage(
      id: $id,
    ) {
        status
    }
  }
`;

export const updateStaticPageImageMutation = gql`
mutation($id: Int, $pageBanner: String) {
  updateStaticPageImage(id: $id, pageBanner: $pageBanner) {
      status
  }
}`;

export const updateSiteSettingsMutation = gql`
mutation updateSiteSettings(
    $siteName: String,
    $siteTitle: String,
    $metaDescription: String, 
    $facebookLink: String,
    $twitterLink: String,
    $instagramLink: String,
    $metaKeyword: String,
    $homeLogo: String,
    $youtubeLink: String,
    $currency: String,
    $distanceUnits: String,
    $appForceUpdate: String,
    $eaterAndroidVersion: String,
    $eaterIosVersion: String,
    $driverAndroidVersion: String,
    $driverIosVersion: String,
    $preferredDelivery: String,
    $favicon: String
    $contactNumber: String,
    $contactEmail: String,
    $contactSkype: String,
    $notificationInterval: String,
    $requestTimeTone: String,
    $requestToneFile: String,
    $orderAcceptInterval: String
    $ogImage: String
    ) {
        updateSiteSettings(
        siteName: $siteName
        siteTitle: $siteTitle
        metaDescription: $metaDescription
        facebookLink: $facebookLink
        twitterLink: $twitterLink
        instagramLink: $instagramLink
        metaKeyword: $metaKeyword
        homeLogo: $homeLogo
        youtubeLink: $youtubeLink
        currency: $currency
        distanceUnits: $distanceUnits
        appForceUpdate: $appForceUpdate
        eaterAndroidVersion: $eaterAndroidVersion
        eaterIosVersion: $eaterIosVersion
        driverAndroidVersion: $driverAndroidVersion
        driverIosVersion: $driverIosVersion
        preferredDelivery: $preferredDelivery
        favicon: $favicon
        contactNumber: $contactNumber
        contactEmail: $contactEmail
        contactSkype:  $contactSkype
        notificationInterval: $notificationInterval
        requestTimeTone:$requestTimeTone
        requestToneFile:$requestToneFile
        orderAcceptInterval: $orderAcceptInterval,
        ogImage : $ogImage
        ){
            status
            errorMessage
        }
    }
`;

export const updateTone = gql`
  mutation updateTone {
  		updateTone {
  		status
  		}
  	}
  `;

export const updateStaticPage = gql`
mutation updateStaticPage(
  $id: Int,
  $content: String,
  $metaTitle: String,
  $metaDescription: String,
  $pageBanner: String,
) {
  updateStaticPage(
    id: $id,
    content: $content,
    metaTitle: $metaTitle,
    metaDescription: $metaDescription,
    pageBanner: $pageBanner,
  ) {
      status
  }
}
`;

export const uploadInsuranceImageMutation = gql`
mutation uploadInsuranceImage($userId: String, $vehicleInsurance: String) {
    uploadInsuranceImage(userId:$userId, vehicleInsurance:$vehicleInsurance) {
      status
    }
  }
`;

export const uploadLicenceBackImageMutation = gql`
mutation uploadLicenceBackImage($userId:ID,$licenceBack:String){
    uploadLicenceBackImage(userId:$userId, licenceBack:$licenceBack) {
      status
    }
  }
`;

export const uploadLicenceFrontImageMutation = gql`
mutation uploadLicenceFrontImage($userId:ID,$licenceFront:String){
    uploadLicenceFrontImage(userId:$userId, licenceFront:$licenceFront) {
      status
    }
  }
`;

export const uploadProfileImageMutation = gql`
mutation uploadProfileImage($userId:ID,$picture:String){
    uploadProfileImage(userId:$userId, picture:$picture) {
      status
    }
  }
`;

export const uploadRcbookImageMutation = gql`
mutation uploadRcbookImage($userId: String, $vehicleRC: String) {
    uploadRcbookImage(userId:$userId, vehicleRC:$vehicleRC) {
      status
    }
  }
`;

export const addContentPageDetails = `
  mutation addContentPageDetails(
    $id: Int,
    $metaTitle: String,
    $metaDescription: String,
    $pageUrl: String,
    $pageTitle: String,
    $content: String,
    $pageBanner: String
  ) {
    addContentPageDetails(
      id: $id,
      metaTitle: $metaTitle,
      metaDescription: $metaDescription,
      pageUrl: $pageUrl,
      pageTitle: $pageTitle,
      content: $content,
      pageBanner: $pageBanner
    ) {
        status
    }
  }
  `;

export const updateContentPageDetails = `
mutation updateContentPageDetails(
  $id: Int,
  $metaTitle: String,
  $metaDescription: String,
  $pageUrl: String,
  $pageTitle: String,
  $content: String,
  $pageBanner: String
) {
  updateContentPageDetails(
    id: $id,
    metaTitle: $metaTitle,
    metaDescription: $metaDescription,
    pageUrl: $pageUrl,
    pageTitle: $pageTitle,
    content: $content,
    pageBanner: $pageBanner
  ) {
      status
  }
}
`;