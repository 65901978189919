import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import Navbar from 'react-bootstrap/Navbar';
import cx from 'classnames';
import { connect } from 'react-redux';
import { flowRight as compose } from 'lodash';

import Link from '../../../Link';
import RestaurantNavigation from '../RestaurantNavigation/RestaurantNavigation';

import history from '../../../../history';
import { api, logoUploadDir } from '../../../../config';

import s from './RestaurantHeader.css';
class RestaurantHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: 0,
      location: null
    };
  }
  componentDidMount() {
    this.setState({ location: history.location.pathname })
  }

  componentDidUpdate() {
    if (!this.state.location) {
      this.setState({ location: history.location.pathname })
    }
  }

  openMenu = async () => {
    this.setState({
      isOpen: 1,
    })
  }

  openClose = async () => {
    this.setState({
      isOpen: 0,
    })
  }

  render() {
    const { location } = this.state;
    const { logo, siteName, isLogin, isOpen } = this.props

    return (
      <div className={cx(s.root, 'mainMenu')}>
        <Navbar expand="lg" className={cx(s.navBg, isLogin ? 'restaurant' : '')}>
          <Navbar.Brand className={s.restaurantBrand}>
            <Link className={s.brand} to="/">
              <img
                src={api.apiEndpoint + logoUploadDir + logo}
                alt={siteName}
                className={s.restaurantBrandLogo}
              />
            </Link>
          </Navbar.Brand>
          {!isLogin && <Navbar.Toggle aria-controls="basic-navbar-nav" className={cx(s.borderNone, s.outlineNone)}
            children={
              <div className={'menuToggle'} onClick={() => this.openMenu()}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
          />}
          <Navbar.Collapse id="basic-navbar-nav" className={cx({ [s.menuOpened]: this.state.isOpen == 1 }, s.justifyFlexEnd, isLogin ? 'restaurant' : s.menuClosed)} in={isOpen}>
            <div onClick={() => this.openClose()}>
              <div className={cx(s.restaurantCloseButton, { [s.restaurantResponseCloseButton]: location && (location == '/shop' || location === '/shop/') }, { ['closeButtonHeaderRTL']: location && (location === '/shop' || location === '/shop/') })}> &#x2715; </div>
              <RestaurantNavigation />
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

const mapState = (state) => ({
  logo: state?.siteSettings?.data?.homeLogo,
  siteName: state?.siteSettings?.data?.siteName,
  isLogin: state?.runtime?.isRestaurantAuthenticated
})
const mapDispatch = {

}

export default compose(withStyles(s), connect(mapState, mapDispatch))(RestaurantHeader)