import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

import ViewReviewDetails from '../../../components/SiteAdmin/ViewReviewDetails';
import Loader from '../../../components/Common/Loader/Loader';
import NotFound from '../../not-found/NotFound';

import viewReview from './viewReview.graphql';

import s from './ViewReview.css';
export class ViewReview extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired
    };

    static defaultProps = {
        reviewData: {
            loading: true
        }
    };

    render() {
        const { reviewData, reviewData: { loading }, title, isAdmin } = this.props;

        if (!loading && (!reviewData?.viewReview || reviewData?.viewReview?.status != 200)) {
            return <NotFound title={'Page Not Found'} isAdmin={isAdmin} />
        } else {
            return (
                <Loader type={"page"} show={loading}>
                    <div className={s.root}>
                        <div className={s.container}>
                            <div className={s.paddingRoutesSection}>
                                {
                                    !loading && <ViewReviewDetails
                                        data={reviewData}
                                        title={title}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </Loader>
            );
        }
    }
}

export default compose(
    withStyles(s),
    graphql(viewReview, {
        name: 'reviewData',
        options: (props) => ({
            variables: {
                id: props.id
            },
            fetchPolicy: 'network-only',
            ssr: false
        })
    })
)(ViewReview);
