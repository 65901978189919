import showToaster from '../../helpers/showToaster';
import { deleteUserMutation as mutation } from '../lib/graphql';
import {
    USER_DELETE_SUCCESS,
    USER_DELETE_START,
    USER_DELETE_ERROR
} from '../../constants/index';

const deleteUser = (id, userType) => {
    return async (dispatch, getState, { client }) => {
        try {
            dispatch({
                type: USER_DELETE_START,
                payload: {
                    deleteLoading: true
                }
            });
            const { data } = await client.mutate({
                mutation,
                variables: {
                    id
                }
            });
            if (data?.deleteUser?.status === 200) {
                dispatch({
                    type: USER_DELETE_SUCCESS,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'userTypeDeleted', toasterType: 'success', requestContent: userType === 2 ? 'The driver' : 'The shopper' });
                return {
                    status: data?.deleteUser?.status
                };
            } else {
                dispatch({
                    type: USER_DELETE_ERROR,
                    payload: {
                        deleteLoading: false
                    }
                });
                showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.deleteUser?.errorMessage });
                return {
                    status: 400
                };
            }
        } catch (err) {
            showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: err });
            dispatch({
                type: USER_DELETE_ERROR,
                payload: {
                    deleteLoading: false
                }
            });
            return {
                status: 400
            };
        }
    };
};

export default deleteUser;