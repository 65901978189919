exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._79jX9 {\n  padding-left: 20px;\n  padding-right: 20px;\n}\n\n._3rznM {\n  margin: 0 auto;\n  padding: 0 0 40px;\n  max-width: var(--max-content-width);\n}\n\n._1slhP {\n  margin: 0 0 32px;\n  margin: 0 0 2rem;\n}\n\n._1lRcu {\n  font-size: 24px;\n  font-size: 1.5rem;\n}\n\n._2a_Yz h1,\n._2a_Yz h2,\n._2a_Yz h3,\n._2a_Yz h4,\n._2a_Yz h5,\n._2a_Yz h6 {\n  font-size: 18px;\n  font-size: 1.125rem;\n}\n\n._2a_Yz pre {\n  white-space: pre-wrap;\n  font-size: 14px;\n  font-size: 0.875rem;\n}\n\n._2a_Yz img {\n  max-width: 100%;\n}\n\n._39g60 {\n  font-size: 0.8em;\n  color: #777;\n}", ""]);

// exports
exports.locals = {
	"root": "_79jX9",
	"container": "_3rznM",
	"newsItem": "_1slhP",
	"newsTitle": "_1lRcu",
	"newsDesc": "_2a_Yz",
	"publishedDate": "_39g60"
};