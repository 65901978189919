import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout'
import EditUser from './EditUser';
import { checkUserAvailability } from '../../../actions/siteadmin/checkUserAvailability';
import { restrictUrls } from '../../../helpers/adminPrivileges';
import messages from '../../../locale/messages';

async function action({ store, params, intl }) {
    const title = intl.formatMessage(messages.editRider);

    // From Redux Store
    let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
    let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
    let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
    if (!isAdminAuthenticated) {
        return { redirect: '/siteadmin/login' }
    }
    // Admin restriction
    if (!restrictUrls('/siteadmin/users/', adminPrivileges, privileges)) {
        return { redirect: '/siteadmin' };
    }

    const id = Number(params.id);

    let response;
    await store.dispatch(checkUserAvailability(id)).then(function (res) {
        response = res;
    });

    return {
        title,
        component: (
            <AdminLayout>
                <EditUser title={title} id={id} isAdmin={isAdminAuthenticated} response={response} />
            </AdminLayout>
        )
    }
}

export default action;
