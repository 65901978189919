import React from 'react';
import AdminLayout from '../../../../components/Layout/AdminLayout';
import EditPromoCode from './EditPromoCode';
import { restrictUrls } from '../../../../helpers/adminPrivileges';
import messages from '../../../../locale/messages';

function action({ store, params, intl }) {
  const title = intl.formatMessage(messages.editPromoCode);

  // From Redux Store
  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;
  let adminPrivileges = store.getState().account.privileges && store.getState().account.privileges.privileges;
  let privileges = store.getState().commonSettings && store.getState().commonSettings.privileges;
  if (!isAdminAuthenticated) {
    return { redirect: '/siteadmin/login' };
  }
  // Admin restriction
  if (!restrictUrls('/siteadmin/promo-code/edit/', adminPrivileges, privileges)) {
    return { redirect: '/siteadmin' };
  }
  return {
    title,
    component: (
      <AdminLayout>
        <EditPromoCode title={title} id={params.id} isAdmin={isAdminAuthenticated} />
      </AdminLayout>
    ),
  };
}

export default action;
