exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".A-BCY {\n  padding-left: 0;\n  padding-right: 0;\n  min-height: 100vh;\n  height: 100%;\n  width: 100%;\n  background: #fff;\n  margin-top: 25px;\n}", ""]);

// exports
exports.locals = {
	"root": "A-BCY"
};