import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import withStyles from 'isomorphic-style-loader/withStyles';

import PayoutList from './PayoutList';
import EmptyList from './PayoutList/EmptyList';
import Loader from '../../Common/Loader';

import s from './Payout.css';
class Payout extends Component {

  static defaultProps = {
    loading: true,
    data: []
  };

  constructor(props) {
    super(props);

    this.state = {
      initialLoad: true
    };
  }

  componentDidMount() {
    const { reset } = this.props;
    this.setState({
      initialLoad: false
    });

    reset('PayoutForm');

  };

  render() {
    const { data, loading } = this.props;
    const { initialLoad } = this.state;
  
    return (
      <Loader type={'page'} show={initialLoad || loading}>
        <div >
          {data?.length > 0 ? <PayoutList data={data} /> : <EmptyList />}
        </div>
      </Loader>
    )
  }
}

const mapState = (state) => ({
  data: state.payout.data,
  loading: state.payout.getPayoutLoading
});

const mapDispatch = {
  reset
};

export default withStyles(s)(connect(mapState, mapDispatch)(Payout));