exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1qwXA {\n\tpadding: 0px 15px 0px;\n}\n\n._3m-Rh {\n\tmargin: 0 auto;\n\tpadding: 0px;\n\tmax-width: 380px;\n}\n\n._2w64G {\n\tmax-width: 568px;\n\twidth: 100%;\n\tpadding: 10px;\n}\n\n._1NCAq {\n\tpadding: 0px;\n}\n\n.NFUzx {\n\tfont-weight: bold;\n\tfont-size: 20px;\n\tmargin-bottom: 10px;\n}\n\n@media screen and (max-width: 767px) {\n\t._2w64G {\n\t\tmargin: 0px auto;\n\t}\n\n\t._1qwXA {\n\t\tpadding: 15px;\n\t}\n}\n\n.MMaf2 ._9e3xV {\n\tpadding: 12px 12px 10px 26px;\n\tborder-bottom: none;\n}\n\n.MMaf2 ._1RYVs {\n\tfont-size: 30px !important;\n\tfont-weight: normal;\n\tcolor: rgb(118,\n\t\t\t118,\n\t\t\t118);\n\topacity: 0.7;\n\tmargin-left: 5px;\n\tdisplay: inline-block;\n\tmargin: -3px 0 0 0;\n\toutline: 0;\n}\n\n.MMaf2 .X1lhx {\n\tborder-radius: 0px;\n}\n\n@media screen and (max-width: 640px) {\n\t.MMaf2 .X1lhx {\n\t\tborder-radius: 0px;\n\t}\n}", ""]);

// exports
exports.locals = {
	"root": "_1qwXA",
	"container": "_3m-Rh",
	"logInModalContainer": "_2w64G",
	"logInModalBody": "_1NCAq",
	"forgetHead": "NFUzx",
	"forgotModal": "MMaf2",
	"modal-header": "_9e3xV",
	"close": "_1RYVs",
	"modal-content": "X1lhx"
};