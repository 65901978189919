export const shopRoutes = [
    {
        path: '/shop',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/home'));
            reject(error);
        }),
    },
    {
        path: '/shop/signup',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/Signup'));
            reject(error);
        }),
    },
    {
        path: '/shop/dashboard',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/Dashboard'));
            reject(error);
        }),
    },
    {
        path: '/shop/login',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/login'));
            reject(error);
        }),
    },
    {
        path: '/shop/edit-profile',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/EditProfile'));
            reject(error);
        }),
    },
    {
        path: '/shop/document',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/Document'));
            reject(error);
        }),
    },
    {
        path: '/shop/password',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/changePassword'));
            reject(error);
        }),
    },
    {
        path: '/shop/payout',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/Payout/PayoutList'));
            reject(error);
        }),
    },
    {
        path: '/shop/addPayout',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/Payout/AddPayout'));
            reject(error);
        }),
    },
    {
        path: '/shop/payout/failure',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/Payout/FailedPayout'));
            reject(error);
        }),
    },
    {
        path: '/shop/operating-hours',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/operationHours'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/category'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/addMenu'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/editMenu'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/:id/item',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/item'));
            reject(error);
        }),
    },
    {
        path: '/shop/preparation-time',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/preparationTime'));
            reject(error);
        }),
    },
    {
        path: '/shop/order-management/:type',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/orderManageContainer'));
            reject(error);
        }),
    },
    {
        path: '/shop/order-details/:type/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/orderDetails'));
            reject(error);
        }),
    },
    {
        path: '/shop/transaction/:type',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/transaction'));
            reject(error);
        }),
    },
    {
        path: '/password/verification',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/changePasswordUsingToken/'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/modifier-groups',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/modifierGroups'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/modifier-groups/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/addModifierGroups'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/modifier-groups/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/editModifierGroups'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/:menuId/item/:subMenuId/add',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/addFoodItem'));
            reject(error);
        }),
    },
    {
        path: '/shop/menu/:menuId/item/:subMenuId/edit/:id',
        load: () => new Promise((resolve, reject) => {
            resolve(require('./shop/editFoodItem'));
            reject(error);
        }),
    },
];