import showToaster from '../../../../../helpers/showToaster';
import addItem from '../../../../../actions/shop/menu/addItem';

async function submit(values, dispatch) {
     let modifiers, tax, variables;
     modifiers = JSON.stringify(values.modifiers);
     tax = values?.tax;
     if (!values?.tax || (values?.tax && values?.tax?.toString().trim() === '')) tax = null;
     variables = Object.assign({}, values, {
          modifiers
     },
          {
               tax
          });

     if (!values.image) showToaster({ messageId: 'uploadImage', toasterType: 'error' })

     else await dispatch(addItem(variables));
}

export default submit;