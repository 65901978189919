import messages from '../../../locale/messages'
import { inputTextLimit , inputDescLimit } from '../../../helpers/fieldRestriction';

const validate = values => {

  const errors = {}

  if (!values?.siteName) {
    errors.siteName = messages.required;
  } else if (values?.siteName?.trim() == "") {
    errors.siteName = messages.required;
  } else if (values.siteName.length > inputTextLimit) {
    errors.siteName = messages.inputTextLimitError;
  }

  if (!values?.siteTitle) {
    errors.siteTitle = messages.required;
  } else if (values?.siteTitle?.trim() == "") {
    errors.siteTitle = messages.required
  } else if (values.siteTitle.length > inputTextLimit) {
    errors.siteTitle = messages.inputTextLimitError;
  }

  if (!values?.metaDescription) {
    errors.metaDescription = messages.required
  } else if (values?.metaDescription.trim() == '') {
    errors.metaDescription = messages.required
  } else if (values?.metaDescription?.length > inputDescLimit) {
    errors.metaDescription = messages.inputDescLimitError;
  }

  if (!values?.metaKeyword) {
    errors.metaKeyword = messages.required
  } else if (values?.metaKeyword?.trim() == '') {
    errors.metaKeyword = messages.required
  } else if (values?.metaKeyword?.length > inputDescLimit) {
    errors.metaKeyword = messages.inputDescLimitError;
  }

  if (!values?.currency) {
    errors.currency = messages.required
  }

  if (!values?.twitterLink) {
    errors.twitterLink = messages.required
  } else if (values?.twitterLink?.trim() == '') {
    errors.twitterLink = messages.required
  } else if (values?.twitterLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.twitterLink)) {
      errors.twitterLink = messages.validUrl;
    }
  }

  if (!values?.youtubeLink) {
    errors.youtubeLink = messages.required
  } else if (values?.youtubeLink.trim() == '') {
    errors.youtubeLink = messages.required
  } else if (values?.youtubeLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.youtubeLink)) {
      errors.youtubeLink = messages.validUrl;
    }
  }

  if (!values?.instagramLink) {
    errors.instagramLink = messages.required
  } else if (values?.instagramLink.trim() == '') {
    errors.instagramLink = messages.required
  } else if (values?.instagramLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.instagramLink)) {
      errors.instagramLink = messages.validUrl;
    }
  }

  if (!values?.facebookLink) {
    errors.facebookLink = messages.required
  } else if (values?.facebookLink.trim() == '') {
    errors.facebookLink = messages.required
  } else if (values?.facebookLink) {
    if (!/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/i.test(values.facebookLink)) {
      errors.facebookLink = messages.validUrl;
    }
  }

  if (!values?.eaterAndroidVersion) {
    errors.eaterAndroidVersion = messages.required;
  } else if (values?.eaterAndroidVersion.toString().trim() === '') {
    errors.eaterAndroidVersion = messages.required;
  } else if (!/^\d+(\.\d+){0,2}$/i.test(values?.eaterAndroidVersion)) {
    errors.eaterAndroidVersion = messages.invalidVersionNumber;
  }

  if (!values?.eaterIosVersion) {
    errors.eaterIosVersion = messages.required
  } else if (values?.eaterIosVersion.trim() == '') {
    errors.eaterIosVersion = messages.required
  } else if (!/^\d+(\.\d+){0,2}$/i.test(values?.eaterIosVersion)) {
    errors.eaterIosVersion = messages.invalidVersionNumber;
  }
  if (!values?.driverAndroidVersion) {
    errors.driverAndroidVersion = messages.required
  } else if (values?.driverAndroidVersion.trim() == '') {
    errors.driverAndroidVersion = messages.required
  } else if (!/^\d+(\.\d+){0,2}$/i.test(values?.driverAndroidVersion)) {
    errors.driverAndroidVersion = messages.invalidVersionNumber;
  }
  if (!values?.driverIosVersion) {
    errors.driverIosVersion = messages.required
  } else if (values?.driverIosVersion.trim() == '') {
    errors.driverIosVersion = messages.required
  } else if (values?.driverIosVersion && !/^\d+(\.\d+){0,2}$/i.test(values?.driverIosVersion)) {
    errors.driverIosVersion = messages.invalidVersionNumber;
  }

  if (!values?.contactEmail) {
    errors.contactEmail = messages.required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values?.contactEmail)) {
    errors.contactEmail = messages.emailInvalid;
  }

  if (!values?.contactNumber) {
    errors.contactNumber = messages.required;
  } else if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(values?.contactNumber)) {
    errors.contactNumber = messages.phoneError;
  }

  if (values?.contactSkype?.toString().trim() === '') {
    errors.contactSkype = messages.invalid;
  } else if (values?.contactSkype?.length > inputTextLimit) {
    errors.contactSkype = messages.inputTextLimitError;
  }

  if (!values?.notificationInterval || values?.notificationInterval?.toString().trim() == "") {
    errors.notificationInterval = messages.required;
  } else if (values?.notificationInterval) {
    if (isNaN(values?.notificationInterval)) {
      errors.notificationInterval = messages.invalid;
    } else if (Number(values?.notificationInterval) < 0.5) {
      errors.notificationInterval = messages.notificationIntervalInvalid;
    } else if (Number(values?.notificationInterval) >= 100) {
      errors.notificationInterval = messages.notificationIntervalInvalid1;
    }
  }

  if (!values?.orderAcceptInterval || values?.orderAcceptInterval.toString().trim() == "") {
    errors.orderAcceptInterval = messages.required;
  } else if (values?.orderAcceptInterval && (isNaN(values?.orderAcceptInterval) || Number(values?.orderAcceptInterval) <= 0)) {
    errors.orderAcceptInterval = messages.invalid;
  }

  return errors
}

export default validate;
