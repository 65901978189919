import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Loader from '../../../../components/Common/Loader/Loader';
import PromoCodeForm from '../../../../components/SiteAdmin/PromoCode/PromoCodeForm';
import NotFound from '../../../not-found/NotFound';

import getPromoCodeQuery from './getPromoCode.graphql';
import messages from '../../../../locale/messages';

import s from './EditPromoCode.css';

class EditPromoCode extends React.Component {
  static defaultProps = {
    promoCode: {
      loading: true
    }
  };

  render() {
    const { title, promoCode: { loading, getPromoCode }, isAdmin } = this.props;

    let initialValues = {};

    if (!loading && getPromoCode) {
      initialValues = getPromoCode;
    }

    if (!loading && !getPromoCode) {
      return <NotFound title={'Page Not Found'} isAdmin={isAdmin} />
    } else {
      return (
        <Loader type={"page"} show={loading}>
          <div className={s.root}>
            <div className={s.container}>
              <div className={s.heading}>
                <FormattedMessage {...messages.editPromoCode} />
              </div>
              <div className={s.paddingRoutesSection}>
                {
                  !loading && <PromoCodeForm initialValues={initialValues} />
                }
              </div>
            </div>
          </div>
        </Loader>
      );
    }
  }
}

export default compose(
  withStyles(s),
  graphql(getPromoCodeQuery, {
    name: 'promoCode',
    options: (props) => ({
      ssr: false,
      fetchPolicy: 'network-only',
      variables: {
        id: props.id
      }
    })
  }))(EditPromoCode);