import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';

import { api, categoryUploadDir } from '../../../config';
import s from './HomeItem.css';

export class HomeItem extends Component {
    render() {
        const { categoryName, categoryImage } = this.props;

        return (
            <>
                <div className={cx('homeItemsSliderItems')}>
                    <div className={cx('homeItemsSliderImgContainer')}>
                        <img
                            src={api.apiEndpoint + "/images/category/medium_" + categoryImage}
                        />
                    </div>
                    <div className={cx('homeItemsSliderLabel')}>
                        <label>{categoryName}</label>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default withStyles(s)(connect(mapStateToProps, mapDispatchToProps)(HomeItem)) 
