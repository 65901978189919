const errorMessage = (messageId, requestContent) => {
    const message = {
        somethingWentWrong: requestContent || 'Something went wrong! Please try again.',
        emailCheck: "Oops! We don't recognize this email address",
        isBan: 'Sorry, it looks like your account has been blocked. Please contact our support team.',
        adminLoginCheck: 'Sorry, It looks like you are already logged-in as an admin. Please logout and try again.',
        userLoginCheck: 'Sorry, It looks like you are already logged-in with your account. Please logout and try again.',
        loginCheck: 'Please login with your account and try again.',
        loginStatus: 'Currently, you are logged in.',
        noRecord: 'Sorry, No records found.',
        accountApproval: 'Oops! You account has not been approved yet. Please our contact support for the help.',
        activateFood: 'Oops! Please activate at least one food item to go available.',
        activateSubmenu: 'Oops! Please activate at least one sub-menu to go available.',
        activateMenu: 'Oops! Please activate at least one menu to go available.',
        unableToFindAccount: 'Oops! We are unable to find your account. Please logout and try again.',
        contactSupport: 'Oops! We are unable to find your account. Please contact support for help.',
        accountDeactivated: 'Oops! Your account has been deactivated. Please contact support for help.',
        notLoggedIn: 'Oops! It looks like you have not logged-in. Please login with your account.',
        incorrectPassword: 'The password you entered is incorrect.',
        invalidEmail: "Oops! We don't recognize this email address",
        loginAdminUser: 'Please login as an admin user and try again.',
        unableToFindBooking: 'Oops! Unable to find the booking information. Please try again',
        notAuthorized: 'You haven\'t authorized for this action.',
        unableToFindRequest: 'Unable to find the requested delivery vehicle.',
        pageNotFound: 'Oops! The page not found!',
        emailAlreadyExist: 'Oops, the provided email is already exits.',
        numberAlreadyExist: 'Oops, the provided phone number is already exits.',
        dietaryCheck: "Oops, the provided dietary type doesn't exist.",
        cuisineCheck: "Oops, the provided cuisine type doesn't exist.",
        noAccountExist: 'No account exists for the given email id',
        invalidToken: 'Invalid token',
        unableToUpdateModifier: 'Oops! Unable to update a modifier group. Please try again.',
        noDeliveryAround: 'Sorry, no delivery around your shop now.',
        nearestDeliveryPartner: 'We will find the nearest delivery partner to pick up the order!',
        orderAlreadyExist: 'It looks like the order has already ',
        orderNotAvailable: "Oops! It looks like the order is not available.",
        failedToCreatePayout: 'failed to create a payout',
        notUpdated: 'Not Updated',
        invalidCode: 'Invalid Verification Code',
        confirmPassword: 'Wrong Confirm Password',
        currentPassword: 'Wrong Current Password',
        alreadyExist: 'Sorry, it looks like the email or phone number is already exist.',
        addFoodItem: 'Please add a food item.',
        updateHours: 'Operation Hours Not Updated',
        preparationTimeUpdated: 'Your Preparation Times have been successfully updated.',
        profileUpdated: 'Your Profile has been successfully updated.',
        imageUploaded: 'The image has been uploaded!',
        documentUpload: 'Your Document has been successfully uploaded.',
        addContent: "Please add content for the page.",
        uploadDocument: 'Please upload document.',
        modifierUnavailable: 'The modifier is unavailable at the moment. Please activate it on the "Modifier Groups" section.',
        payoutAccountRemoved: 'Payout account have been removed.',
        statusChanged: "Status has changed",
        hoursUpdate: 'The operating hours have been updated successfully.',
        serviceUpdated: "Service Fee Updated!",
        vehicleImageRequired: "Vehicle type image is required.",
        endTimeMustHigher: 'The end time must be higher than the start time.',
        uploadImage: 'Please upload the image for food item',
        mismatchPassword: 'Password mismatch.Please try again.',
        uploadRestaurantImage: 'Please upload shop image.',
        updateFailed: 'Updating failed',
        defaultPaymentMethod: 'Default payment method has been set.',
        reasonDeleted: 'Cancel Reason has been deleted successfully.',
        siteSettingChangesApplied: 'Site settings changes have been successfully applied',
        homepageSettingsApplied: 'Homepage settings changes have been successfully applied',
        selectLocation: 'Please select location',
        locationUpdated: 'The Location has been updated successfully.',
        featureNotAvailable: 'This feature is currently not available. Please contact admin for more information.',
        catchError: `Oops! Something went wrong. ${requestContent}`,
        changesUpdated: 'Your changes has been updated successfully.',
        itemAdded: 'A new food item has been added successfully.',
        menuAdded: 'A new Menu added successfully',
        itemDeleted: 'The Item has been deleted successfully.',
        deleteMenu: "The Menu has been deleted successfully.",
        subMenuDeleted: 'The Sub Menu has been deleted successfully.',
        itemStatusUpdated: 'The Item status has been updated successfully.',
        menuStatusUpdated: 'The Menu status has been updated successfully.',
        subMenuUpdated: 'The Sub Menu status has been updated successfully.',
        modifierChangesUpdated: 'The modifier changes have been updated successfully.',
        modifierChangesCreated: 'The modifier has been created successfully.',
        subMenuAdded: 'A new Sub Menu added successfully.',
        modifierDeleted: 'The Modifier has been deleted successfully.',
        modifierStatusUpdate: 'The Modifier status has been updated successfully.',
        orderStatusUpdate: 'The Order status has been updated successfully!',
        preparationTimeUpdated: "Your Preparation Times have been successfully updated.",
        signedUp: "You have successfully signed up.",
        locationAdded: "The Location has been added successfully.",
        locationDeleted: "The Location has been deleted successfully.",
        driverUpdated: "Driver has been updated!",
        eaterInfoUpdated: "The shopper information has been updated successfully!",
        cuisineAdded: "The cuisine has been added successfully.",
        cuisineUpdated: "The cuisine has been updated successfully.",
        settingUpdated: "The setting has been updated successfully.",
        settingCreated: "The setting has been created successfully.",
        settingStatusUpdated: "The setting status has been updated successfully!",
        settingDeleted: 'The setting has been deleted Successfully!',
        cuisineDeleted: "The cuisine has been deleted.",
        configUpdated: "Configurations updated successfully!",
        currencyStatusUpdated: "The currency status has been updated successfully.",
        baseCurrencySet: "Base Currency is set successfully",
        failedSetBaseCurrency: "Failed to set Base Currency",
        paymentCurrencySuccess: "Allowed Payment Currency Success",
        vehicleDeleted: "The vehicle has been deleted.",
        vehicleTypeStatus: "The vehicle type status has been updated.",
        restaurantDeleted: "The Shop has been deleted successfully.",
        restaurantDetailsUpdated: "The Shop details has been updated successfully.",
        restaurantHomeUpdated: "The Shop Homepage has been updated successfully.",
        requestedChangesUpdated: "The requested changes has been updated successfully.",
        paymentGatewayUpdated: "Payment method status has been updated successfully.",
        fareDeleted: "The fare has been deleted successfully.",
        promoCodeDeleted: "Promo Code has been deleted successfully.",
        passwordUpdated: "Password is updated successfully",
        statusFailed: "Failed to change status",
        tryAgain: `${requestContent}`,
        updateServiceFailed: 'Update Service Fee failed',
        cuisineImageRequired: 'Cuisine image is required.',
        provideAddress: 'Please provide address.',
        checkTimeSlot: 'Please check your time slot. Either time slot is invalid or already available.',
        failedSetPaymentCurrency: "Failed to set allowed payment currency success",
        sentResetLink: "Reset link email is on its way to your inbox",
        deleteContentPage: "Deleted successfully!",
        deleteContentPageFailed: "Deleted failed!",
        addedFailed: 'The page URL already exist!',
        updatePageFailed: 'Updating Content Page Details failed',
        updatePage: 'Changes are updated!',
        changesAdded: 'Changes are added!',
        existUrl: 'The page URL already exist!',
        pageDetailsFailed: 'Creating Content Page Details failed',
        providePhoneNumber: 'Please provide phone number',
        adminDetailsChanged: 'Admin details changed successfully!',
        adminDetailsFailed: 'Your changes to admin details failed!',
        reasonDeletionFailed: 'Cancel Reason deletion failed',
        trySmallestSizeImg: 'Maximum upload size exceeded!, Try with smallest size image',
        sendResetLinkFailed: requestContent ? requestContent : "Something went wrong, please try again later",
        changePasswordSuccess: 'Your password has changed, you can try login now!',
        changePasswordFailed: `Something went wrong, please try again later. ${requestContent}`,
        featureNotAvailable: 'This feature is currently not available. Please contact admin for more information.',
        failedMessage: requestContent ? requestContent : "Something Went wrong, please try again later",
        failedToUpdateStatus: requestContent ? requestContent : "Failed to update status!",
        unableToCreateAdmin: "Oops, something went wrong. Unable to create an admin user.",
        userHasOrder: 'Oops! It looks like the user has an order and unable to delete them',
        loginAsAdmin: 'Please login as an admin and continue the action.',
        changeUserStatus: 'Please do not change the user status. It looks like the user is having an active delivery order and try again later.',
        banUser: 'Please do not ban the user. It looks like the user is having an active delivery order and try again later.',
        unableToUpdateUserInfo: 'Oops! Something went wrong, unable to update the user information.',
        unableToFindAdminRole: 'Sorry, unable to find this admin role.',
        unableToDeleteAdminRole: 'Oops! Unable to delete the admin role due to some admin user using this role.',
        roleNotValid: 'Oops, it looks like the chosen role is not valid. Please try with different valid role.',
        activeAtLeastOnePaymentMethod: 'AtLeast one payment method option must be active.',
        enableStripe: 'Please enable Stripe Payment to activate the wallet.',
        cantDeleteCuisine: "Can't delete the cuisine as the cuisine is used by shops",
        cantUpdateStatusCuisine: "Can't update the status as the cuisine is used by shops",
        unableToDisableDietary: 'Oops! Unable to disable the dietary setting due to the shops are using it. Please change the dietary setting on their profile and try again to disable.',
        cantDisableBaseCurrency: "Sorry, you can't disable the base currency. Please change the platform's base currency and try again.",
        currencyUsed: 'Oops! Unable to disable this currency. The currency is used for the platform\'s preferred currency. Please change the platform\'s preferred currency and try again',
        usersUsingThisCurrency: 'Oops! Unable to disable this currency. Our users are using this currency.',
        unableToChangeCurrencyStatus: 'Oops! Unable to change the currency status. Please try again.',
        unableToUpdateDeliveryImage: 'Unable to update the delivery vehicle image.',
        removeFare: "Sorry, unable to delete. The chosen location is used on the manage fare. Please remove the fare and try again.",
        unableToDeleteRestaurant: 'Oops! Unable to delete the shop. They have some active orders.',
        fareAlreadyExist: 'Sorry, it looks like the fare is already exist for the chosen location and category.',
        unableToCreateFare: 'Sorry, unable to create a fare for the chosen location and category.',
        phoneNumberVerified: 'Phone Number verified successfully',
        adminRoleUpdate: `Admin Role has been ${requestContent}  successfully!`,
        adminRoleDeleted: 'Admin Role has been deleted successfully!',
        adminUserUpdate: `Admin User has been ${requestContent}  successfully!`,
        adminUserDeleted: "Admin User has been deleted successfully!",
        cuisineImageUploaded: 'Cuisine image has been uploaded successfully!',
        vehicleTypeImageUploaded: 'The vehicle type image has been uploaded!',
        statusUpdated: `The ${requestContent} status has been updated successfully.`,
        promoCodeStatus: `Promo Code has been ${requestContent} successfully`,
        cancelReasonStatus: `Cancel Reason has been ${requestContent} successfully.`,
        userTypeDeleted: `${requestContent} has been deleted successfully.`,
        vehicleUpdated: 'Vehicle has been updated!',
        insuranceImageUploaded: 'Insurance image has been uploaded!',
        licenseImageUploaded: 'License images has been uploaded!',
        profileImageUploaded: 'Profile image has been uploaded!',
        rcBookImageUploaded: 'RC book image has been uploaded!',
        maxUploadSizeExceeded: 'Maximum upload size exceeded!, Try with smallest size image',
        invalidImageFile: `You are trying to upload invalid image file. Please upload ${requestContent} format image file.`,
        enterPhoneNumber: 'Please Enter Phone Number',
        stripePayoutError: requestContent,
        trySmallestSizeAudio: 'Maximum upload size Exceeded!, Try with smallest size audio',
        invalidAudioFile: 'You are trying to upload invalid file. Please upload mp3 format audio file.',
        settingStatus: `Oops! Something went wrong. Unable to ${requestContent} the setting.`,
        unableToCreatePromoCode: 'Oops! Something went wrong! Unable to create a new promo code. Please try again.',
        alreadyPromoCodeUsed: 'Oops! It looks like already this promo code is used. Please try again with different promo code name.',
        unableToDeletePromoCode: "Oops! something went wrong. Unable to delete the promo code.",
        unableToUpdatePromoCode: 'Oops! Something went wrong! Unable to update the promo code. Please try again.',
        noDeliveryPartnerFound: 'Sorry, no delivery partner found.',
        unableToProceedRequestedAction: 'Oops! Unable to proceed the requested action. Please try again.',
        unableToProceedRefund: requestContent ? `Unable to proceed the refund due to ${requestContent}` : 'Oops! Something went wrong! Unable to proceed the refund.',
        unableToUpdateOperatingHours: requestContent ? requestContent : 'Oops! Something went wrong. Unable to update the operating hours and try again.',
        vehicleTypeStatusSuccess: `The vehicle type has been ${requestContent} successfully.`,
        defaultPayoutError: 'Unable to set Default',
        unableToFindOrder: 'Unable to find the order.',
        reviewNotFound: 'Something went wrong! Review not found!',
        orderNotFound: 'Something went wrong, Order Not Found!',
        invalidOgImg: 'Please upload the OG Image',
        error: 'Error!',
        success: 'Success!',
        warning: 'Warning!',
        offlineError: 'You are offline. Please try again later',
        bookingNotFound: 'Oops! It looks like the booking is not found and please try again.',
        deliveryDone: 'Delivery done!',
        requestToEater: 'We will send the request to shopper to pick up the order!',
        payoutCurrencySuccess: 'The payout currency has been updated successfully',
        payoutCurrencyFailed: 'You are not allowed to update the preferred payout currency',
        cantDisablePayoutCurrency:'You cannot disable this currency as it is the preferred payout currency. Please set a different currency as the preferred payout currency before disabling this one'
    }

    return message[messageId] || 'Something went wrong! Please try again.';
}

export default errorMessage;