import {
    COMMON_SETTING_MODAL_OPEN,
    COMMON_SETTING_MODAL_CLOSE,
    ITEM_MODAL_OPEN,
    ITEM_MODAL_CLOSE,
    SUB_MENU_MODAL_OPEN,
    SUB_MENU_MODAL_CLOSE,
    MODAL_OPEN,
    MODAL_CLOSE,
    TRANSACTION_MODAL_OPEN,
    TRANSACTION_MODAL_CLOSE,
    DECLINE_REASON_MODAL_CLOSE,
    DECLINE_REASON_MODAL_OPEN,
    OPEN_HEADER_MODAL,
    CLOSE_HEADER_MODAL,
    OPEN_ADMIN_ROLES_MODAL,
    CLOSE_ADMIN_ROLES_MODAL,
    OPEN_ADMIN_USER_MODAL,
    CLOSE_ADMIN_USER_MODAL
} from '../../constants';

import { initialize, reset } from 'redux-form';

export function openCommonSettingsModal(formName, values) {
    return (dispatch, getState) => {
        dispatch(initialize(formName, values));

        dispatch({
            type: COMMON_SETTING_MODAL_OPEN,
            isCommonSettingModalOpen: true
        })
    }
}

export function closeCommonSettingsModal() {
    return (dispatch, getState) => {
        dispatch(reset('addCommonSetting'));
        dispatch(reset('editCommonSetting'));
        dispatch({
            type: COMMON_SETTING_MODAL_OPEN,
            isCommonSettingModalOpen: false
        })
    }
}

export function openItemModal(menuId, subMenuId) {
    return (dispatch, getState) => {
        dispatch({
            type: ITEM_MODAL_OPEN,
            isItemModalOpen: true,
            menuId: menuId,
            subMenuId: subMenuId
        })
    }
}

export function closeItemModal() {
    return (dispatch, getState) => {
        dispatch({
            type: ITEM_MODAL_CLOSE,
            isItemModalOpen: false
        })
    }
}

export function openSubMenuModal(menuId) {
    return (dispatch, getState) => {
        dispatch({
            type: SUB_MENU_MODAL_OPEN,
            isSubMenuModalOpen: true,
            menuId: menuId
        })
    }
}

export function closeSubMenuModal() {
    return (dispatch, getState) => {
        dispatch({
            type: SUB_MENU_MODAL_CLOSE,
            isSubMenuModalOpen: false
        })
    }
}

export function openModal(name, data) {

    return (dispatch, getState) => {
        dispatch({
            type: MODAL_OPEN,
            payload: {
                name,
                status: true,
                data
            }
        })
    }
}

export function closeModal(name) {
    return (dispatch, getState) => {
        dispatch({
            type: MODAL_CLOSE,
            payload: {
                name,
                status: false
            }
        })
    }
}

export function openTransactionModal() {
    return (dispatch, getState) => {
        dispatch({
            type: TRANSACTION_MODAL_OPEN,
            isTransactionModalOpen: true,
        })
    }
}

export function closeTransactionModal() {
    return (dispatch, getState) => {
        dispatch({
            type: TRANSACTION_MODAL_CLOSE,
            isTransactionModalOpen: false
        })
    }
}

export function openDeclineReasonModal(id, page) {
    return (dispatch, getState) => {
        dispatch({
            type: DECLINE_REASON_MODAL_OPEN,
            isDeclineModalOpen: true,
            bookingId: id,
            currentPage: page
        })
    }
}

export function closeDeclineReasonModal() {
    return (dispatch, getState) => {
        dispatch({
            type: DECLINE_REASON_MODAL_CLOSE,
            isDeclineModalOpen: false
        })
    }
}


export function openHeaderModal(modalType) {
    return (dispatch, getState) => {
        dispatch({
            type: OPEN_HEADER_MODAL,
            payload: {
                modalType,
                actionValue: true
            }
        });
    };
}

export function closeHeaderModal(modalType) {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_HEADER_MODAL,
            payload: {
                modalType,
                actionValue: false
            }
        });
    };
}

export function editItemModal(name, index1, index2) {

    return (dispatch, getState) => {
        let values = {};
        if (getState().form.AddItemForm && getState().form.AddItemForm.values) {
            values = getState().form.AddItemForm.values.modifiers[index1].modifierGroups[index2];
        }
        let variables = Object.assign({}, values, {
            editId: index1
        });
        dispatch(initialize(name, variables));
        dispatch({
            type: ITEM_MODAL_OPEN,
            isItemModalOpen: true,
        })
    }
}

export function openAdminRolesModal(type, formData) {
    return (dispatch, getState) => {
        if (type === 'edit') {
            dispatch(initialize("AdminRolesForm", formData, true));
        }

        dispatch({
            type: OPEN_ADMIN_ROLES_MODAL,
            payload: {
                adminRolesModal: true,
                adminRolesModalType: type
            }
        });
    }
}

export function closeAdminRolesModal() {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_ADMIN_ROLES_MODAL,
            payload: {
                adminRolesModal: false
            }
        });
    }
}

export function openAdminUserModal(type, formData) {
    return (dispatch, getState) => {
        if (type === 'edit') {
            dispatch(initialize("AdminUserForm", formData, true));
        }

        dispatch({
            type: OPEN_ADMIN_USER_MODAL,
            payload: {
                adminUserModal: true,
                adminUserModalType: type
            }
        });
    }
}

export function closeAdminUserModal() {
    return (dispatch, getState) => {
        dispatch({
            type: CLOSE_ADMIN_USER_MODAL,
            payload: {
                adminUserModal: false
            }
        });
    }
}

export function openConfirmModal({ name, data, status }) {
    return (dispatch, getState) => {
        dispatch({
            type: MODAL_OPEN,
            payload: {
                name,
                status,
                data
            }
        })
    }
}