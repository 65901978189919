import {
  SET_PAYOUT_START,
  SET_PAYOUT_SUCCESS,
  SET_PAYOUT_ERROR,
  UPDATE_PAYMENT_STATUS_START,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS_ERROR,
  MODAL_OPEN,
} from '../../../constants';
import showToaster from '../../../helpers/showToaster';
import showErrorMessage from '../../../helpers/errorMessages/showErrorMessage';
import { updatePayoutMutation as mutation, updatePaymentMutation, getPaymentMethodsQuery } from '../../lib/graphql';

export function updatePayoutStatus(id, requestStatus, fieldName) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: SET_PAYOUT_START,
    });

    try {

      const { data } = await client.mutate({
        mutation,
        variables: {
          id,
          requestStatus,
          fieldName
        }
      });

      if (data?.updatePayout?.status === 200) {
        dispatch({
          type: SET_PAYOUT_SUCCESS,
        });
        showToaster({ messageId: 'requestedChangesUpdated', toasterType: 'success' })
        return {
          status: 200
        };
      } else {

        dispatch({
          type: SET_PAYOUT_ERROR,
          payload: {
            error: await showErrorMessage({ errorCode: 'somethingWentWrong' })
          }
        });

        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: data?.updatePayout?.errorMessage });
      }

    } catch (error) {
      dispatch({
        type: SET_PAYOUT_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'catchError', toasterType: 'error', requestContent: error });
    }
  };
}

export function updatePaymentMethod(id, status) {
  return async (dispatch, getState, { client }) => {

    dispatch({
      type: UPDATE_PAYMENT_STATUS_START,
    });

    try {
      const { data } = await client.mutate({
        mutation: updatePaymentMutation,
        variables: { id, status },
        refetchQueries: [{ query: getPaymentMethodsQuery }]
      });

      if (data?.updatePayment?.status === 200) {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_SUCCESS,
        });
        showToaster({ messageId: 'paymentGatewayUpdated', toasterType: 'success' })
      } else {
        dispatch({
          type: UPDATE_PAYMENT_STATUS_ERROR,
          payload: {
            error: data?.updatePayment?.errorMessage
          }
        });
        showToaster({ messageId: 'failedToUpdateStatus', toasterType: 'error', requestContent: data?.updatePayment?.errorMessage });
        return false;
      }

      dispatch({
        type: MODAL_OPEN,
        payload: {
          name: 'isConfirmModalOpen',
          status: false,
        }
      });

    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_STATUS_ERROR,
        payload: {
          error
        }
      });
      showToaster({ messageId: 'failedToUpdateStatus', toasterType: 'error' });
      return false;
    }
    return true;
  };
}

