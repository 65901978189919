import showErrorMessage from "./errorMessages/showErrorMessage";

const generateStripePayoutToken = async (stripe, values) => {
    try {
        let status = 200, errorMessage, createPersonToken;
        let accountToken, personToken, person = {}, address = {};
        let business_type = values.businessType || 'individual';
        let account = {
            business_type,
            tos_shown_and_accepted: true
        };

        address = {
            line1: values?.address1,
            city: values?.city,
            state: values?.state,
            country: values?.country,
            postal_code: values?.zipcode
        }

        if (business_type === 'individual') {
            account['individual'] = {
                email: values?.email,
                first_name: values?.firstname,
                last_name: values?.lastname,
                address
            };
        } else {
            account['company'] = {
                name: values?.firstname,
                address
            };
        };

        const createAccountToken = await stripe.createToken('account', account);

        if (business_type === 'company') {
            person = {
                address,
                email: values.email,
                relationship: {
                    representative: true
                }
            };

            createPersonToken = await stripe.createToken('person', { person });
        };

        if (createAccountToken?.token && (business_type !== 'company' || (business_type === 'company' && createPersonToken?.token))) {
            accountToken = createAccountToken?.token?.id;
            personToken = createPersonToken?.token?.id;
        } else {
            status = 400;
            if (!createAccountToken?.token) {
                errorMessage = createAccountToken?.message || (createAccountToken?.error?.message);
            } else if (!createPersonToken?.token) {
                errorMessage = createPersonToken?.message || (createPersonToken?.error?.message);
            } else {
                errorMessage = await showErrorMessage({ errorCode: 'unableToProceedRequestedAction' })
            }
        };

        return {
            status,
            errorMessage,
            result: {
                accountToken,
                personToken
            }
        };
    } catch (error) {
        return {
            status: 400,
            errorMessage: await showErrorMessage({ errorCode: 'catchError', error })
        };
    };
}

export default generateStripePayoutToken;