import history from '../../../history';
import showToaster from '../../../helpers/showToaster';
import { setLoaderStart, setLoaderComplete } from '../../../actions/loader/loader';
import { addPromoCodeMutation as addMutation, updatePromoCodeMutation as updateMutation } from '../../lib/graphql';
import {
  ADD_PROMOCODE_START,
  ADD_PROMOCODE_SUCCESS,
  ADD_PROMOCODE_ERROR
} from '../../../constants';

export function addPromoCode(values) {
  return async (dispatch, getState, { client }) => {
    let status, errorMessage;

    try {
      dispatch({
        type: ADD_PROMOCODE_START,
        payload: {
          promoCodeLoading: true
        }
      });

      dispatch(setLoaderStart('AddPromoCode'));

      const mutation = values?.id ? updateMutation : addMutation;

      const { data } = await client.mutate({
        mutation,
        variables: {
          id: values?.id,
          title: values?.title,
          description: values?.description,
          code: values?.code,
          type: values?.type || 1,
          promoValue: values?.promoValue,
          currency: values?.currency,
          expiryDate: values?.expiryDate !== '' ? values?.expiryDate : null,
          isEnable: values?.isEnable
        }
      });

      if (data && values?.id) {
        status = data?.updatePromoCode?.status;
        errorMessage = data?.updatePromoCode?.errorMessage;
      } else if (data && !values?.id) {
        status = data?.addPromoCode?.status;
        errorMessage = data?.addPromoCode?.errorMessage;
      }

      if (status && status === 200) {
        history.push('/siteadmin/promo-code/list');
        showToaster({ messageId: 'promoCodeStatus', toasterType: 'success', requestContent: values?.id ? 'updated' : 'added' })
        await dispatch({
          type: ADD_PROMOCODE_SUCCESS,
          payload: {
            promoCodeLoading: false
          }
        });
        dispatch(setLoaderComplete('AddPromoCode'));
      } else {
        showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: errorMessage })
        await dispatch({
          type: ADD_PROMOCODE_ERROR,
          payload: {
            promoCodeLoading: false,
            error: errorMessage
          }
        });
        dispatch(setLoaderComplete('AddPromoCode'));
      }
    } catch (error) {
      showToaster({ messageId: 'somethingWentWrong', toasterType: 'error', requestContent: error })
      dispatch(setLoaderComplete('AddPromoCode'));
      await dispatch({
        type: ADD_PROMOCODE_ERROR,
        payload: {
          promoCodeLoading: false,
          error: errorMessage
        }
      });
    }
  }
};
